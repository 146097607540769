import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// Public
import JobListingSearchView from "../views/Public/JobListing/JobListingSearchView.vue";
import JobListingView from "../views/Public/JobListing/JobListingView.vue";
import CompanySearchView from "../views/Public/Companies/CompanySearchView.vue";
import CompanyView from "../views/Public/Companies/CompanyView.vue";
import CompanyRegisterView from "../views/Public/Authentication/Company/CompanyRegisterView.vue";
import CompanyLoginView from "../views/Public/Authentication/Company/CompanyLoginView.vue";
import CompanyResetPasswordView from "../views/Public/Authentication/Company/CompanyResetPasswordView.vue";
import ManageEmailSubscriptionView from "../views/Public/EmailSubscription/ManageEmailSubscriptionView.vue";
import ContactUsView from "../views/Public/Content/ContactUsView.vue";
import TokenView from "../views/Public/TokenView.vue";
import AdvertiseView from "../views/Public/Content/AdvertiseView.vue";
import AboutUsView from "../views/Public/Content/AboutUsView.vue";
import PrivacyPolicyAndTermsView from "../views/Public/Content/PrivacyPolicyAndTermsView.vue";
import SiteMapView from "../views/Public/SiteMapView.vue";
import ArticlesView from "../views/Public/Content/Articles/ArticlesView.vue";
import ArticleView from "../views/Public/Content/Articles/ArticleView.vue";
import JobNotificationsView from "../views/Public/Content/JobNotificationsView.vue";
import NotFoundView from "../views/Public/NotFoundView.vue";
// Landing Pages
import RemoteJobs from "../views/Public/Content/LandingPages/RemoteJobs.vue";
import RustJobs from "../views/Public/Content/LandingPages/RustJobs.vue";
import UserExpierienceJobs from "../views/Public/Content/LandingPages/UserExperienceJobs.vue";
import AIJobs from "../views/Public/Content/LandingPages/AIJobs.vue";
// Company Account
import CompanyDashboardView from "../views/CompanyAccount/CompanyDashboardView.vue";
import CreateJobListingView from "../views/CompanyAccount/JobListing/CreateJobListingView.vue";
import ManageJobListingView from "../views/CompanyAccount/JobListing/ManageJobListingView.vue";
import CompanyManageView from "../views/CompanyAccount/Company/CompanyManageView.vue";
import CompanyManageUsersView from "../views/CompanyAccount/Company/CompanyManageUsersView.vue";
import CompanyInvoiceHistoryView from "../views/CompanyAccount/Company/CompanyInvoiceHistoryView.vue";
import CompanySubscriptionView from "../views/CompanyAccount/Company/CompanySubscriptionView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "jobSearch",
    component: JobListingSearchView,
  },
  {
    path: "/company-login",
    name: "companyLogin",
    component: CompanyLoginView,
  },
  {
    path: "/company-register",
    name: "companyRegister",
    component: CompanyRegisterView,
  }, 
  {
    path: "/company-reset-password",
    name: "companyResetPassword",
    component: CompanyResetPasswordView,
  },
  {
    path: "/company-dashboard",
    name: "companyDashboard",
    component: CompanyDashboardView,
  },
  {
    path: "/hiring-companies/:companyname",
    name: "company",
    component: CompanyView,
  },
  {
    path: "/hiring-companies",
    name: "companies",
    component: CompanySearchView,
  },
  {
    path: "/hiring-companies/:companyname/:identifier",
    name: "jobListing",
    component: JobListingView,
  },
  {
    path: "/company-account/job-listing/",
    name: "createJobListing",
    component: CreateJobListingView,
  },
  {
    path: "/company-account/job-listing/:identifier",
    name: "manageJobListing",
    component: ManageJobListingView,
  },  
  {
    path: "/company-account/manage",
    name: "manageCompany",
    component: CompanyManageView,
  },
  {
    path: "/company-account/manage-users",
    name: "companyManageUsers",
    component: CompanyManageUsersView,
  },
  {
    path: "/company-account/invoice-history",
    name: "companyInvoiceHistory",
    component: CompanyInvoiceHistoryView,
  },
  {
    path: "/company-account/subscription",
    name: "companySubscription",
    component: CompanySubscriptionView,
  },
  {
    path: "/email-subscription/manage/:groupidentifier/",
    name: "manageEmailSubscription",
    component: ManageEmailSubscriptionView,
  }, 
  {
    path: "/contact-us",
    name: "contactUs",
    component: ContactUsView,
  }, 
  {
    path: "/token/:tokentype/:token",
    name: "token",
    component: TokenView,
  }, 
  {
    path: "/advertise-with-us/",
    name: "advertise",
    component: AdvertiseView,
  }, 
  {
    path: "/about-us/",
    name: "aboutUs",
    component: AboutUsView,
  }, 
  {
    path: "/job-notifications/",
    name: "jobNotifications",
    component: JobNotificationsView,
  }, 
  {
    path: "/policies",
    name: "policies",
    component: PrivacyPolicyAndTermsView,
  }, 
  {
    path: "/sitemap",
    name: "siteMap",
    component: SiteMapView,
  }, 
  {
    path: "/articles",
    name: "articles",
    component: ArticlesView,
  }, 
  {
    path: "/articles/:slug",
    name: "article",
    component: ArticleView,
  }, 
  {
    path: "/remote-jobs",
    name: "landingPageRemoteJobs",
    component: RemoteJobs,
  }, 
  {
    path: "/user-experience-jobs",
    name: "landingPageUserExpierienceJobs",
    component: UserExpierienceJobs,
  }, 
  {
    path: "/rust-jobs",
    name: "landingPageRustJobs",
    component: RustJobs,
  }, 
  {
    path: "/ai-jobs",
    name: "landingPageAIJobs",
    component: AIJobs,
  },  
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundView,
  }, 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
