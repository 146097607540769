<template>
  <div data-test-key="sitemap-page">
    <h1>SiteMap</h1>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <ul style="max-width: 500px; margin: auto; text-align: left;">
      <li v-bind:key="siteMap.Name" v-for="siteMap in siteMaps">
        <ul><b>{{ siteMap.Name }}</b>
          <li v-bind:key="siteMapItem.LinkUrl" v-for="siteMapItem in siteMap.Items">
            <a v-bind:href="siteMapItem.LinkUrl">{{ siteMapItem.Name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import { siteAPI } from "@/api/site";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "HtmlSiteMap",
  components : {
    FeedbackNotification
  },
  data() {
    return {
      pageLoading : true,
      notification : {
        text : "",
        level : "",
      },
      siteMaps : [],
    };
  },
 created(){
    this.getSiteMap()
 },
  mounted() {
    document.title = 'SiteMap - TheJobTap.com'
  },
  methods: {
    async getSiteMap() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await siteAPI.getSiteMap();
          this.siteMaps = result.data;
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }    
    }
  },
};
</script>