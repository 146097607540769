import Vuex from "vuex"

const state = {
    companyUserStore: null
};

const store = new Vuex.Store({
    state,
    getters:{
        companyUserStore: (state) => {
            //console.log(state.companyUserStore)
            return state.companyUserStore;
        }
    },
    actions:{
        companyUserStore(context, companyUserStore){
            context.commit('companyUserStore', companyUserStore)
        }
    },
    mutations:{
        companyUserStore(state, companyUserStore) {
            state.companyUserStore = companyUserStore;
        }
    }
})

export default store
