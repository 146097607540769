<template>
  <div data-test-key="about-us-page">
    <h1>About Us</h1>

    <h2>Values</h2>
    <p>Job site specialising in web3.</p>
    <p>UX first - No faff - simple and contextual - to the point!.</p>
    <p>Help to connect you with jobs relevent to you!</p>
    <p>Can provide multi-faceted search (build a tag/search query based on many properties -> unlike most sites that are not facired) for specific best match jobs matching you</p>
    <p>No account required for applicants. you will be directed to the application point</p>
    <p>We vet companies and prevent any scams or harvesting of your emails!</p>

    <h2>Email subscriptions</h2>
    <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>
    <p>Easy to manage email subscriptions - Show email sub managment.</p>
    <p>Specific email subs based on all your search criteria -> no spam.. only one email per your selected frequency.</p>
    <p>Manage your email subscription and turn it off with ease!</p>
    <p>Can subscribe to any level of the search allowing us to send very relevant jobs</p>

    <h2>Social medias and outreach platforms</h2>
    <p>
      <a target="_blank"  href="https://twitter.com/thejobtap">Twitter</a>, 
      <a target="_blank"  href="https://facebook.com/thejobtap">Facebook</a>, 
      <a target="_blank"  href="https://reddit.com/thejobtap">Reddit</a>, 
      <a target="_blank"  href="https://tiktok.com/thejobtap">TikTok</a>,
      <a target="_blank"  href="https://linkedin.com/thejobtap">LinkedIn</a>,
      <a target="_blank"  href="https://discord.com/thejobtap">Discord?</a>,
      <a target="_blank"  href="https://telegram.com/thejobtap">Telegram TO DO</a>
    </p>
  </div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = 'About Us - TheJobTap.com'
  }
};
</script>