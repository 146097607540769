<template>
  <h2>Invite Member</h2>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <ul style="max-width: 500px; margin:auto; text-align: left;">
      <li>Max 5 accounts</li>
    </ul>
    <div>
      <p>
        <input class="formInput" v-model="state.inviteCompanyUserForm.emailAddress" placeholder="enter email address to invite..." />
        <ValidationMessage v-if="v$.inviteCompanyUserForm.emailAddress.$error" :validationMessage ="v$.inviteCompanyUserForm.emailAddress.$errors[0].$message"/>
        <button class="btn" v-on:click="inviteNewCompanyUserHandler">Send Invite</button>
      </p>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>

import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "CompanyInviteNewUserForm",
  components : {
    FeedbackNotification, ValidationMessage
  }, 
  emits: ["formsubmitted"],
  setup () {
    const state = reactive({
      inviteCompanyUserForm: {
          emailAddress : '',
          }
      });
      const rules = computed(() => {
        return {
          inviteCompanyUserForm: {
            emailAddress: { required, email },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isInviteCompanyUserFormSubmitted : false,
    };
  },
  methods: {
    async inviteNewCompanyUserHandler() {  
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {          
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await companyUserAPI.create(this.state.inviteCompanyUserForm.emailAddress);
            this.state.emailAddress = "";
            this.notification = { text : "Company account invited. Awaiting email confirmation.", level : "success" };  
            // notify the parent view  
            this.$emit('formsubmitted');
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
      }
    },
  },
};
</script>