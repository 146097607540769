import axios from "axios";
// const client = axios.create({ baseURL: process.env.APP_SERVICE_ENDPOINT });
const client = axios.create({ baseURL: "https://api.thejobtap.com/api" });
//const client = axios.create({ baseURL: "https://localhost:4300/api" });
// get the users auth token if exists
if(localStorage.getItem('jbpw-auth-token') != null)
{
    client.defaults.headers.common['X-jbpw-auth'] = '' + localStorage.getItem('jbpw-auth-token');
}

export const httpClient = client;