<template>
  <h2>Set company Details</h2>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
      <p>You can update this information later</p>
      <p>
        <input class="formInput" v-model="state.setCompanyDetailsForm.name" placeholder="name" /><br/>
        <ValidationMessage v-if="v$.setCompanyDetailsForm.name.$error" :validationMessage ="v$.setCompanyDetailsForm.name.$errors[0].$message" />
      </p>
      <p>
        <input class="formInput" v-model="state.setCompanyDetailsForm.website" placeholder="website" /><br/>
        <ValidationMessage v-if="v$.setCompanyDetailsForm.website.$error" :validationMessage ="v$.setCompanyDetailsForm.website.$errors[0].$message" />
      </p>
    <p>
      <label>Description</label><br/>
      <WysiwygEditor
        :editorText="state.setCompanyDetailsForm.description"             
        @onChange="updateCompanyDetailsFormDescription" />
      <input type="hidden" v-model="state.setCompanyDetailsForm.description"/>
      <ValidationMessage v-if="v$.setCompanyDetailsForm.description.$error" :validationMessage ="v$.setCompanyDetailsForm.description.$errors[0].$message" />
    </p>
    <button class="btn" v-on:click="setCompanyDetailsHandler()">Set Company Details</button>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapGetters } from "vuex";
import WysiwygEditor from '@/components/WysiwygEditor.vue'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { WysiwygEditor, ValidationMessage, FeedbackNotification },
  setup () {
    const state = reactive({
      setCompanyDetailsForm: {
          name : '',
          website : '',
          description : '',      
          }
      });
      const rules = computed(() => {
        return {
          setCompanyDetailsForm: {
              name: { required },
              website: { required },
              description: { required },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading : true,
      notification : {
        text : "",
        level : "",
      }
    };
  },
  created() {
    this.getCompany();
  },
  computed: {
    ...mapGetters(['companyUserStore'])
  },
  methods: {
    updateCompanyDetailsFormDescription(e){
      console.log("updated wysiwyg editor")
      this.state.setCompanyDetailsForm.description = e;
    },
    async setCompanyDetailsHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.setDetails(
            this.state.setCompanyDetailsForm.name, 
            this.state.setCompanyDetailsForm.website, 
            this.state.setCompanyDetailsForm.description);
            // success   
            if(result.data)
            {
              this.notification = { text : "Company updated", level : "success" };      
              this.companyUserStore.HasSetCompanyDetails = true;
              this.$store.dispatch('companyUserStore', this.companyUserStore);
              // notify the parent view  
              this.$emit('formsubmitted');
            }              
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };      
        }
      }
    },
    async getCompany() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.get();
          this.state.setCompanyDetailsForm.name = result.Name;
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };      
      }
    },
  },
};
</script>