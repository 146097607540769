<template>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div class="_contactUs" v-else>
    <div v-if="!isContactUsFormSubmitted">
      <p>
        <input class="formInput" data-test-key="input-contact-email" v-model="state.contactUsForm.emailAddress" placeholder="email address" /><br/>
        <ValidationMessage v-if="v$.contactUsForm.emailAddress.$error" :validationMessage ="v$.contactUsForm.emailAddress.$errors[0].$message" />
      </p>
      <p>
        <textarea class="formInputLarge" data-test-key="input-contact-text" v-model="state.contactUsForm.contactText" placeholder="contact text"></textarea><br/>
        <ValidationMessage v-if="v$.contactUsForm.contactText.$error" :validationMessage ="v$.contactUsForm.contactText.$errors[0].$message" />
      </p>
      <button class="btn" data-test-key="button-contact-submit" v-on:click="submitContactUsHandler">Contact Us</button>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import { siteAPI } from "@/api/site";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components : {
    FeedbackNotification, ValidationMessage
  }, 
  setup () {
    const state = reactive({
      contactUsForm: {
          emailAddress : '',
          contactText : '',
          }
      });
      const rules = computed(() => {
        return {
          contactUsForm: {
            emailAddress: { required, email },
            contactText: { required },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isContactUsFormSubmitted : false,
    };
  },
  methods: {
    async submitContactUsHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await siteAPI.submitContactUsRequest(
            this.state.contactUsForm.emailAddress, this.state.contactUsForm.contactText);
            this.notification = { text : "Contact form submitted. We will get back to you asap.", level : "success" };  
            this.isContactUsFormSubmitted = true;
            // notify the parent view  
            this.$emit('formsubmitted');
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };      
        } 
      }
    }
  },
};
</script>