<template>
    <div data-test-key="email-subscription-page">
      <h1>Get Notified</h1>
  
      <h2>Email subscriptions</h2>
      <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>
      <p>Easy to manage email subscriptions - Show email sub managment.</p>
      <p>Specific email subs based on all your search criteria -> no spam.. only one email per your selected frequency.</p>
      <p>Manage your email subscription and turn it off with ease!</p>
      <p>Can subscribe to any level of the search allowing us to send very relevant jobs</p>
  
      <h2>Telegram</h2>
      <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>

    </div>
  </template>
  
  <script>
  export default{
    data() {
      return {
      };
    },
    mounted() {
      document.title = 'Get Notified - TheJobTap.com'
    }
  };
  </script>