<template>
<div data-test-key="manage-job-listing-page">
    <div v-if="jobListing != null">
      <h1>Manage Job Listing</h1>
      <!-- <p>upsell products here</p>
      <p>job apps graph</p> -->
      <table style="max-width: 400px; margin: auto;">
        <tr>
          <td style="width:160px; text-align: left;"><b>Created:</b></td>
          <td style="text-align: right;">{{ this.jobListing.DateCreated }}</td>
        </tr>
        <tr v-if="this.jobListing.DateUpdated != null">
          <td style="width:160px; text-align: left;"><b>Last updated:</b></td>
          <td style="text-align: right;">{{ this.jobListing.DateUpdated }}</td>
        </tr>
        <tr>
          <td style="width:160px; text-align: left;"><b>Application count:</b></td>
          <td style="text-align: right;">{{ this.jobListing.ApplicationCount }}</td>
        </tr>
      </table>
      <JobListingForm @formsubmitted="this" />
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex"; 
import JobListingForm from "@/components/Forms/JobListingForm.vue";
import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {  
  name: "UpdateJobListingView",
  components : {
    JobListingForm
  }, 
  data() {
    return {
      jobListing : null
    };
  },
 computed: {
    ...mapGetters(['companyUserStore'])
 },
 created(){
    if(this.companyUserStore == null)
    {
      this.$router.push('/');      
    }
    else{
      this.getJobListingByIdentifier();
    }
 },
  mounted() {
    document.title = 'Manage Job - TheJobTap.com'
  },
 methods: {
   async getJobListingByIdentifier() {
     const result = await log.catchPromise(() =>
       pageStatus.observe(async () => {
        const result = await jobListingAPI.getByIdentifier(this.$route.params.identifier);
        this.jobListing = result;
      })
    );
    if (!result.success) {

    }
  },
  formsubmitted(e) {

  }
 }
};
</script>