import { EmailSubscription } from "@/domain/emailSubscription/emailSubscription";
import { httpClient } from "./http-client";
const basePath = "/email-subscription";

export const emailSubscriptionAPI = {
  create: async (emailAddress: string, categoryNames: string[], companyName: string, minSalary: string, 
    location: string,isRemote: string, frequency: string, searchTerm: string) => 
  {
    const url = basePath + "/create";
    return await httpClient.post(url, {
      Emailaddress: emailAddress,
      SearchSubscription : {
        CategoryNames: categoryNames,
        CompanyName: companyName,
        MinSalary: minSalary,
        Location: location,
        IsRemote: isRemote,
        SearchTerm: searchTerm,
      },
      Frequency : frequency
    });
  },
  remove: async (identifier: string) => 
  {
    const url = basePath + "/remove";
    return await httpClient.post(url, {
      Identifier: identifier,
    });
  },
  setActiveStatus: async (identifier: string) => 
  {
    const url = basePath + "/setactivestatus";
    return await httpClient.post(url, {
      Identifier: identifier,
    });
  },
  get: async (groupidentifier: string) => 
  {
    const url = basePath + "/getbygroupedidentifer?groupidentifier=" + groupidentifier;
    const response = await httpClient.get(url);
    return response.data as EmailSubscription;
  },
};