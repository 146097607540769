<template>
  <h2>Company User Invite</h2>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <div v-if="!isFormSubmitted">
      <p>
        <input class="formInput" type="password" v-model="state.processCompanyUserInviteForm.password.password" placeholder="password" /><br/>
        <ValidationMessage v-if="v$.processCompanyUserInviteForm.password.password.$error" :validationMessage ="v$.processCompanyUserInviteForm.password.password.$errors[0].$message" />
      </p>
      <p>
        <input class="formInput" type="password" v-model="state.processCompanyUserInviteForm.password.confirm" placeholder="confirm" /><br/>
        <ValidationMessage v-if="v$.processCompanyUserInviteForm.password.confirm.$error" :validationMessage ="v$.processCompanyUserInviteForm.password.confirm.$errors[0].$message" />
      </p>
      <button class="btn" v-on:click="processCompanyUserInviteHandler">Create Account</button>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  setup () {
    const state = reactive({
      processCompanyUserInviteForm: {
            password: {
                password: "",
                confirm: ""
            }
        },
    });
    const rules = computed(() => {
      return {
        processCompanyUserInviteForm: {
          password: {
            password:  { required, minLength: minLength(8) },
            confirm: { required, sameAs: sameAs(state.processCompanyUserInviteForm.password.password) },
          }
        }
      }
    })
    const v$ = useVuelidate(rules, state)
    return { state, v$ }
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isFormSubmitted : false,
      token : this.$route.params.token,
    };
  },
  methods: {
  async processCompanyUserInviteHandler(event)
  {
    alert()
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await companyUserAPI.processCompanyUserInvite(this.token, this.state.processCompanyUserInviteForm.password.password);
            if(result.data)
            {
              this.notification = { text : "Processed Company User Invite", level : "success" };  
              this.isFormSubmitted = true;
              // notify the parent view  
              this.$emit('formsubmitted', 'Processed Company User Invite');        
            }
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : 'Unable to process Company User Invite' + " [" + result.data + "]", level : "error" };
        }
      }
   },    
  },
};
</script>