<template>
<div data-test-key="create-job-listing-page">
    <h1>Create Job Listing</h1>
      <JobListingForm @formsubmitted="this" />
  </div>
</template>

<script>

import { mapGetters } from "vuex"; 
import JobListingForm from "@/components/Forms/JobListingForm.vue";

export default {  
  name: "CreateJobListingView",
  components : {
    JobListingForm
  }, 
  data() {
    return {

    };
  },
 created() {
    if (this.companyUserStore == null)
    {
      this.$router.push('/')        
    }
 },
  mounted() {
    document.title = 'Create Job - TheJobTap.com';
  },
 computed :{
    ...mapGetters(['companyUserStore'])
 },
  methods: {

  },
};
</script>