<template>
  <div class="">
    <h2>Job Listing</h2>
    <div v-if="!isJobListingFormSubmitted">
      <p>
        <label>Position</label><br/>
        <input class="formInputLarge" v-model="state.jobListingForm.title" placeholder="Position" /><br/>
        <ValidationMessage v-if="v$.jobListingForm.title.$error" :validationMessage ="v$.jobListingForm.title.$errors[0].$message" />
      </p>
      <p>
        <label>Experience Level</label><br/>
        <select class="formInput" v-model="state.jobListingForm.experienceLevelRequiredType">
          <option disabled value="">Please select one</option>
          <option v-bind:key="experienceLevelRequiredType" v-for="experienceLevelRequiredType in experienceLevelRequiredTypes" :value="experienceLevelRequiredType">{{experienceLevelRequiredType}}</option>
        </select><br/>
        <ValidationMessage v-if="v$.jobListingForm.experienceLevelRequiredType.$error" :validationMessage ="v$.jobListingForm.experienceLevelRequiredType.$errors[0].$message" />
      </p>
      <!-- <p>
        <label>Hiring Company Name</label><br/>
        Use my Company Name<input type="checkbox" class="formInput" checked /><br/>
        Hiring for Company<input class="formInput" placeholder="Company Name" />(Leave blank to show no company names)<br/> 
      </p> -->
      <p>
        <label>Job Listing Tags <i>[select up to 5]</i></label><br/>
        <!-- "Role based category used for email subs and search e.g backend-dev, la  / skills list for search e.g nft, coding language -->
        <select class="formInput" v-model="state.jobListingForm.jobListingCategoryNameOne">
          <option disabled value="">Please select one</option>
          <optgroup v-bind:key="jobListingCategoryGroup.Name" v-for="jobListingCategoryGroup in jobListingCategoryGroups" :label="jobListingCategoryGroup.Name">
            <option v-bind:key="jobListingCategory.Name" v-for="jobListingCategory in jobListingCategoryGroup.JobListingCategories" :value="jobListingCategory.Name">{{jobListingCategory.Name}}</option>
          </optgroup>
        </select><br/>
        <select class="formInput" v-model="state.jobListingForm.jobListingCategoryNameTwo">
          <option disabled value="">Please select one</option>
          <optgroup v-bind:key="jobListingCategoryGroup.Name" v-for="jobListingCategoryGroup in jobListingCategoryGroups" :label="jobListingCategoryGroup.Name">
            <option v-bind:key="jobListingCategory.Name" v-for="jobListingCategory in jobListingCategoryGroup.JobListingCategories" :value="jobListingCategory.Name">{{jobListingCategory.Name}}</option>
          </optgroup>
        </select><br/>
        <select class="formInput" v-model="state.jobListingForm.jobListingCategoryNameThree">
          <option disabled value="">Please select one</option>
          <optgroup v-bind:key="jobListingCategoryGroup.Name" v-for="jobListingCategoryGroup in jobListingCategoryGroups" :label="jobListingCategoryGroup.Name">
            <option v-bind:key="jobListingCategory.Name" v-for="jobListingCategory in jobListingCategoryGroup.JobListingCategories" :value="jobListingCategory.Name">{{jobListingCategory.Name}}</option>
          </optgroup>
        </select><br/>
        <select class="formInput" v-model="state.jobListingForm.jobListingCategoryNameFour">
          <option disabled value="">Please select one</option>
          <optgroup v-bind:key="jobListingCategoryGroup.Name" v-for="jobListingCategoryGroup in jobListingCategoryGroups" :label="jobListingCategoryGroup.Name">
            <option v-bind:key="jobListingCategory.Name" v-for="jobListingCategory in jobListingCategoryGroup.JobListingCategories" :value="jobListingCategory.Name">{{jobListingCategory.Name}}</option>
          </optgroup>
        </select><br/>
        <select class="formInput" v-model="state.jobListingForm.jobListingCategoryNameFive">
          <option disabled value="">Please select one</option>
          <optgroup v-bind:key="jobListingCategoryGroup.Name" v-for="jobListingCategoryGroup in jobListingCategoryGroups" :label="jobListingCategoryGroup.Name">
            <option v-bind:key="jobListingCategory.Name" v-for="jobListingCategory in jobListingCategoryGroup.JobListingCategories" :value="jobListingCategory.Name">{{jobListingCategory.Name}}</option>
          </optgroup>
        </select><br/>
        <ValidationMessage v-if="v$.jobListingForm.jobListingCategoryNameOne.$error" :validationMessage ="v$.jobListingForm.jobListingCategoryNameOne.$errors[0].$message" />
      </p>
      <p>
        <label>Description</label><br/>
        <WysiwygEditor v-if="this.isJobListingFormLoaded" 
          :editorText="state.jobListingForm.description"             
          @onChange="updateJobListingDescription" />
        <input type="hidden" v-model="state.jobListingForm.description"/>
        <ValidationMessage v-if="v$.jobListingForm.description.$error" :validationMessage ="v$.jobListingForm.description.$errors[0].$message" />
      </p>
      <p>
        <label>Contract Type</label><br/>
        <select class="formInput" v-model="state.jobListingForm.contractType">
          <option disabled value="">Please select one</option>
          <option v-bind:key="contractType" v-for="contractType in contractTypes" :value="contractType">{{contractType}}</option>
        </select><br/>
        <ValidationMessage v-if="v$.jobListingForm.contractType.$error" :validationMessage ="v$.jobListingForm.contractType.$errors[0].$message" />
      </p>
      <p v-if="state.jobListingForm.contractType == 'Contract'">
        <label>ContractLengthInMonths</label><br/>
        <input class="formInput" v-model="state.jobListingForm.contractLengthInMonths" placeholder="ContractLengthInMonths" /><br/><br/>
        <ValidationMessage v-if="v$.jobListingForm.contractLengthInMonths.$error" :validationMessage ="v$.jobListingForm.contractLengthInMonths.$errors[0].$message" />
      </p>
      <p>
        <label>Hours Per Week</label><br/>
        <input class="formInput" v-model="state.jobListingForm.hoursPerWeek" placeholder="HoursPerWeek" /><br/>
        <ValidationMessage v-if="v$.jobListingForm.hoursPerWeek.$error" :validationMessage ="v$.jobListingForm.hoursPerWeek.$errors[0].$message" />
      </p>
      <p>
        <label>Salary</label><br/>
        From:<input class="formInputSmall" v-model="state.jobListingForm.salary.from" placeholder="Salary from" />
        <ValidationMessage v-if="v$.jobListingForm.salary.from.$error" :validationMessage ="v$.jobListingForm.salary.from.$errors[0].$message" />
        To:<input class="formInputSmall" v-model="state.jobListingForm.salary.to" placeholder="Salary to" /><br/>
        <ValidationMessage v-if="v$.jobListingForm.salary.to.$error" :validationMessage ="v$.jobListingForm.salary.to.$errors[0].$message" />
      </p>
      <p>
        <label>Attendance Requirement</label><br/>
        <select class="formInput" v-model="state.jobListingForm.attendanceRequirement">
          <option disabled value="">Please select one</option>
          <option v-bind:key="attendanceRequirementType" v-for="attendanceRequirementType in attendanceRequirementTypes" :value="attendanceRequirementType">{{attendanceRequirementType}}</option>
        </select><br/>
        <ValidationMessage v-if="v$.jobListingForm.attendanceRequirement.$error" :validationMessage ="v$.jobListingForm.attendanceRequirement.$errors[0].$message" />
      </p>
      <p>
        <label>Location</label><br/>
        <input class="formInput" v-model="state.jobListingForm.location" placeholder="Location" /><br/>
        <ValidationMessage v-if="v$.jobListingForm.location.$error" :validationMessage ="v$.jobListingForm.location.$errors[0].$message" />
      </p>
      <p>
        <label>Application Type</label><br/>
        <select class="formInput" v-model="state.jobListingForm.applicationType">
          <option disabled value="">Please select one</option>
          <option v-bind:key="applicationType" v-for="applicationType in applicationTypes" :value="applicationType">{{applicationType}}</option>
        </select><br/>
        <ValidationMessage v-if="v$.jobListingForm.applicationType.$error" :validationMessage ="v$.jobListingForm.applicationType.$errors[0].$message" />
      </p>
      <p v-if="state.jobListingForm.applicationType != ''">
        <label>Application Type Value</label><br/>
        <input class="formInput" v-model="state.jobListingForm.applicationTypeValue" placeholder="ApplicationTypeValue" /><br/>
        <ValidationMessage v-if="v$.jobListingForm.applicationTypeValue.$error" :validationMessage ="v$.jobListingForm.applicationTypeValue.$errors[0].$message" />
      </p>
      <button class="btn" v-on:click="saveJobListingHandler()">
        <span v-if="isCreate">Create Job Listing</span>
        <span v-else>Update Job Listing</span>
      </button>
      <!--
      <p>Help up Identify what extra tags to add for more specific targeting - This info will not be shown. You dont have to fill this in</p> 
      <label>Skills Required (comma seperate)</label><br/>
      <input v-model="createJobListingForm.skillsRequired" placeholder="SkillsRequired" /><br/> -->
    </div>
    <div v-else>
      <button v-if="this.isCreate" style="padding:10px" v-on:click="handleCreateJobClick()">Create Job Listing</button>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import { jobListingAPI } from "@/api/jobListing";
import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import WysiwygEditor from '@/components/WysiwygEditor.vue'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { WysiwygEditor, ValidationMessage, FeedbackNotification },
  setup () {
    const state = reactive({
      jobListingForm : {
        title : '',
        hoursPerWeek : '',
        location : '',
        attendanceRequirement : '',
        description : '',
        salary : {
          from: '',
          to: ''
        },
        jobListingCategoryNameOne : '',
        jobListingCategoryNameTwo : '',
        jobListingCategoryNameThree : '',
        jobListingCategoryNameFour : '',
        jobListingCategoryNameFive : '',
        contractType : '',
        contractLengthInMonths : '',
        applicationType : '',
        applicationTypeValue : '',
        experienceLevelRequiredType : ''
        // skillsRequired : ['one','two'],
        },
      });
      const rules = computed(() => {
        return {
          jobListingForm: {
            title : { required },
            hoursPerWeek : 0,
            location : { required },
            attendanceRequirement : { required },
            description : { required },
            salary : {
              from: { required },
              to: { required }
            },
            jobListingCategoryNameOne : { required },
            jobListingCategoryNameTwo : {},
            jobListingCategoryNameThree : {},
            jobListingCategoryNameFour : {},
            jobListingCategoryNameFive : {},
            contractType : { required },
            contractLengthInMonths : {},
            applicationType : { required },
            applicationTypeValue : { required },
            experienceLevelRequiredType : { required },
          }
        }})
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      isJobListingFormLoaded : false,
      jobListingIdentifier : this.$route.params.identifier,
      isCreate : this.$route.params.identifier == undefined,
      isUpdateForm : false,
      isJobListingFormSubmitted : false,
      jobListingCategories : [],
      jobListingCategoryGroups : [],
      applicationTypes : ["Email", "WebsiteLink"],
      attendanceRequirementTypes : ["Remote", "Hybrid", "Office"],
      contractTypes : ["FullTime", "PartTime", "Contract"],
      experienceLevelRequiredTypes : ["None", "Entry", "Mid", "Senior"],
    };
  },
  mounted(){
    this.getJobListingCategories(); //remove this
    this.getJobListingCategoryGroups();
    if(!this.isCreate)
    {
      this.getJobListing();
    }
    else
    {
      this.isJobListingFormLoaded = true;
    }
  },
  methods: {
    async handleCreateJobClick(){
      this.$router.push('/company-account/job-listing/');
    },
    updateJobListingDescription(e){
      this.state.jobListingForm.description = e;
    },
    async saveJobListingHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        const jobListingCategoryNames = [];
        if(this.state.jobListingForm.jobListingCategoryNameOne != '')
          jobListingCategoryNames.push(this.state.jobListingForm.jobListingCategoryNameOne);
        if(this.state.jobListingForm.jobListingCategoryNameOne != '')
          jobListingCategoryNames.push(this.state.jobListingForm.jobListingCategoryNameTwo);
        if(this.state.jobListingForm.jobListingCategoryNameOne != '')
          jobListingCategoryNames.push(this.state.jobListingForm.jobListingCategoryNameThree);
        if(this.state.jobListingForm.jobListingCategoryNameOne != '')
          jobListingCategoryNames.push(this.state.jobListingForm.jobListingCategoryNameFour);
        if(this.state.jobListingForm.jobListingCategoryNameOne != '')
          jobListingCategoryNames.push(this.state.jobListingForm.jobListingCategoryNameFive);

        if(this.isCreate)
        {
          await this.createJobListingHandler(jobListingCategoryNames);
        }
        else
        {
          await this.updateJobListingHandler(jobListingCategoryNames);
        }
      }
    },
    async createJobListingHandler(jobListingCategoryNames) {
     
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await jobListingAPI.create(
              this.state.jobListingForm.title, 
              this.state.jobListingForm.skillsRequired, 
              this.state.jobListingForm.hoursPerWeek, 
              this.state.jobListingForm.experienceLevelRequiredType, 
              this.state.jobListingForm.location, 
              this.state.jobListingForm.attendanceRequirement, 
              this.state.jobListingForm.description, 
              this.state.jobListingForm.contractType, 
              jobListingCategoryNames, 
              this.state.jobListingForm.contractLengthInMonths, 
              this.state.jobListingForm.applicationType,
              this.state.jobListingForm.applicationTypeValue,
              this.state.jobListingForm.salary.from,
              this.state.jobListingForm.salary.to,
              );
              // success   
              if(result.data)
              {
                this.notification.text = "Job Listing Saved";
                this.notification.level = "success"
                this.isJobListingFormSubmitted = true; 
                // notify the parent view  
                this.$emit('formsubmitted');        
              }    
            })
          );
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
    },
    async updateJobListingHandler(jobListingTagNames) {
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await jobListingAPI.update(
              this.jobListingIdentifier,
              this.state.jobListingForm.title, 
              this.state.jobListingForm.skillsRequired, 
              this.state.jobListingForm.hoursPerWeek, 
              this.state.jobListingForm.experienceLevelRequiredType, 
              this.state.jobListingForm.location, 
              this.state.jobListingForm.attendanceRequirement, 
              this.state.jobListingForm.description, 
              this.state.jobListingForm.contractType, 
              jobListingTagNames, 
              this.state.jobListingForm.contractLengthInMonths, 
              this.state.jobListingForm.applicationType,
              this.state.jobListingForm.applicationTypeValue,
              this.state.jobListingForm.salary.from,
              this.state.jobListingForm.salary.to);
              // success   
              if(result.data)
              {
                this.notification.text = "Job Listing Saved";
                this.notification.level = "success"
                this.isJobListingFormSubmitted = true; 
                // notify the parent view  
                this.$emit('formsubmitted');        
              }    
            })
          );
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
    },
    async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories();
          const jobListingCategoryNames = [];
          result.map(function(value, key) {
            jobListingCategoryNames.push(value.Name);
          });
          this.jobListingCategories = jobListingCategoryNames;
        })
      );
      if (!result.success) {          
        this.notification = { text : result.data, level : "error" };
      }
    },
    async getJobListingCategoryGroups() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategoryGroups();
          const jobListingCategoryGroupNames = [];
          result.map(function(value, key) {
            const jobListingCategoryNames = [];
            value.JobListingCategories.map(function(value, key) {
              jobListingCategoryNames.push(value);
            });
            jobListingCategoryGroupNames.push(value, jobListingCategoryNames);
          });
          this.jobListingCategoryGroups = jobListingCategoryGroupNames;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };      
      }
    },
    async getJobListing() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.getByIdentifier(this.jobListingIdentifier);
          this.state.jobListingForm.title = result.Title;
          this.state.jobListingForm.hoursPerWeek = result.HoursPerWeek;
          this.state.jobListingForm.location = result.Location;
          this.state.jobListingForm.attendanceRequirement = result.AttendanceRequirement;
          this.state.jobListingForm.description = result.Description;
          this.state.jobListingForm.salary.frequency = result.Salary.Frequency;
          this.state.jobListingForm.salary.from = result.Salary.From;
          this.state.jobListingForm.salary.to = result.Salary.To;
          this.state.jobListingForm.jobListingCategoryNameOne = result.CategoryNames[0]
          this.state.jobListingForm.jobListingCategoryNameTwo = result.CategoryNames[1]
          this.state.jobListingForm.jobListingCategoryNameThree = result.CategoryNames[2]
          this.state.jobListingForm.jobListingCategoryNameFour = result.CategoryNames[3]
          this.state.jobListingForm.jobListingCategoryNameFive = result.CategoryNames[4]
          this.state.jobListingForm.contractType = result.ContractType;
          this.state.jobListingForm.contractLengthInMonths = result.ContractLengthInMonths;
          this.state.jobListingForm.applicationType = result.ApplicationType;
          this.state.jobListingForm.applicationTypeValue = result.ApplicationTypeValue;
          this.state.jobListingForm.experienceLevelRequiredType = result.ExperienceRequiredType;

          this.isJobListingFormLoaded = true;
          }));
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
    },
  },
};
</script>