

import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import store from "./store/vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "App",
  components : {
    Navigation, Footer
  },
  data() {
    return {
      accessToken : localStorage.getItem('jbpw-auth-token') !== null ? 'pc' : '',
    };
  },
  async created() {
    if(localStorage.getItem('jbpw-auth-token') != null)
    {
      const result = await log.catchPromise(() =>
      pageStatus.observe(async () => {
          const result = await companyUserAPI.get();
          store.dispatch('companyUserStore', result);
        })
      );
      if (!result.success) {
          store.dispatch('companyUserStore', null);
          localStorage.removeItem('jbpw-auth-token');
      }  
    }
  },
}

