import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"padding":"30px"}
}
const _hoisted_2 = {
  key: 1,
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return ($data.accessToken !== 'pc')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, [
          _withDirectives(_createElementVNode("input", {
            class: "formInput",
            "data-test-key": "input-access-token",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.accessToken) = $event)),
            placeholder: "Access token"
          }, null, 512), [
            [_vModelText, $data.accessToken]
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Navigation),
        _createVNode(_component_router_view),
        _createVNode(_component_Footer)
      ]))
}