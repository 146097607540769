<template>
<div data-test-key="article-page">
  <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <div v-if="this.article != null" >
        <!-- Page: Breadcrumb -->
        <div class="_divBreadcrumb">
          <p>
            <a href="#" v-on:click="clickArticlesLink">Articles</a> /
            {{ article.Title }}
          </p>
        </div>
      </div>
      <!-- Page: FeedbackNotification -->
      <FeedbackNotification style="margin-bottom: 15px;" 
        v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>   
      <div v-if="this.article != null" >
        <!-- Article: Intro -->
        <h1>{{ article.Title }}</h1>
        <img :src=article.MainImage style="height: 180px; display:inline-block;">
        <br/>
        <br/>
        <!-- Article: Tags -->
        <div style="line-height: 25pt; min-width:450px; display:inline; overflow: hidden;">
            <li style="display:inline-block;" v-bind:key="category" v-for="category in article.Categories" >
            <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;">
                <a>{{ category }}</a>
            </span>
          </li>
        </div>
        <p><b>Posted on:</b> {{ article.DateCreated }}</p>
        <!-- Share: Social media -->
        <p><b>Share links: </b>
          <ShareNetwork class="_linkShare"
              :popup="{width: 400, height: 200}" 
              network="facebook"
              :url=this.pageUrl
              :title=this.article.Title
              :description=this.article.Excerpt
              quote="quote me"
              hashtags="jobs,it">
              <font-awesome-icon title="facebook" icon="fa-brands fa-facebook" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="twitter"
              :url=this.pageUrl
              :title=this.article.Title
              :description=this.article.Excerpt
              twitter-user="TheJobTap">
              <font-awesome-icon title="twitter" icon="fa-brands fa-twitter" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="whatsapp"
              :url=this.pageUrl
              :title=this.article.Title
              :description=this.article.Excerpt>
              <font-awesome-icon title="whatsapp" icon="fa-brands fa-whatsapp" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="telegram"
              :url=this.pageUrl
              :title=this.article.Title
              :description=this.article.Excerpt>
              <font-awesome-icon title="telegram" icon="fa-brands fa-telegram" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="reddit"
              :url=this.pageUrl
              :title=this.article.Title
              :description=this.article.Excerpt>
              <font-awesome-icon title="reddit" icon="fa-brands fa-reddit" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="linkedin"
              :url=this.pageUrl
              :title=this.article.Title
              :description=this.article.Excerpt>
              <font-awesome-icon title="linkedin" icon="fa-brands fa-linkedin" />
          </ShareNetwork>
        </p>
        <button style="padding: 10px; cursor: pointer;" v-on:click="clickRelatedJobSearchLink">view related jobs</button>
        <button style="padding: 10px; cursor: pointer;" v-on:click="clickRelatedArticleSearchLink">view related articles</button>
        <!-- Article: Next and Prevous links -->
        <div style="max-width: 800px; margin: auto; padding: 15px; height: 20px;">
          <button style="float: left; cursor: pointer;" v-if="article.PreviousArticleSlug" v-on:click="clickPostLink(article.PreviousArticleSlug)">prev: {{ article.PreviousArticleTitle }}</button>
          <button style="float: right; cursor: pointer;" v-if="article.NextArticleSlug" v-on:click="clickPostLink(article.NextArticleSlug)">next: {{ article.NextArticleTitle }}</button>
        </div>
        <!-- Article: Content -->
        <WysiwygViewer :viewerText="this.article.Content" />
      </div>  
    </div>
</div>
</template>

<script>
import { contentAPI } from "@/api/content";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "Article",
  components : {
    FeedbackNotification, WysiwygViewer
  }, 
  data() {
    return {
      pageLoading : true,
      article : null,      
      notification : {
        text : "",
        level : "",
      },
      pageUrl : ""
    };
  },
  created(){
    this.getArticle();
    this.pageUrl = window.location.href;
  },
  mounted() {
    //document.title = 'Article - TheJobTap.com'
  },
  methods: {
  async clickArticlesLink() {
    this.$router.push('/articles/');
  },
  clickPostLink(slug) {
    this.$router.push('/articles/' + slug + '/');
    this.getArticleBySlug(slug);
  },
  async clickRelatedJobSearchLink () {
    let querySearch = "?tags=" + this.article.Categories.map(entry => entry).join('&tags=');
    querySearch = querySearch + "&sortOrder=BestMatch";
    this.$router.push('/' + querySearch);
  },
  async clickRelatedArticleSearchLink () {
    let querySearch = "?tags=" + this.article.Categories.map(entry => entry).join('&tags=');
    this.$router.push('/articles/' + querySearch);
  },
  async getArticleBySlug(slug) {
    this.pageLoading = true;
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getArticle(slug);
          this.article = result;
          document.title = this.article.Title + ' - TheJobTap.com'
        })
      );
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
  },
  async getArticle() {
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getArticle(this.$route.params.slug);
          this.article = result;
          document.title = this.article.Title + ' - TheJobTap.com'
        })
      );
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
  }
  }
};
</script>