<template>
  <div style="width: 240px; margin: auto; height: 75px;">
    <b>Email me jobs like this</b>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else class="" >
      <div v-if="!isEmailSubscriptionFormSubmitted" >
        <input data-test-key="input-email-subscription-email" style="width: 240px;" class="formInput" v-model="state.emailSubscriptionForm.emailAddress" placeholder="enter email.." />
        <div>
          <ValidationMessage v-if="v$.emailSubscriptionForm.emailAddress.$error" :validationMessage ="v$.emailSubscriptionForm.emailAddress.$errors[0].$message"/>
        </div>
        <select data-test-key="input-ddl-email-subscription-frequency" style="width: 120px; float: left;" class="formInput" v-model="state.emailSubscriptionForm.frequencyType">
          <option v-bind:key="frequencyType" v-for="frequencyType in emailSubscription.frequencyTypes" :value="frequencyType">{{frequencyType}}</option>
        </select>
        <button data-test-key="button-email-subscription-submit" style="width: 120px; float: right;" class="btn" v-on:click="handleEmailSubscription">Subscribe</button>
      </div>
      <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text"
        :feedbackLevel="this.notification.level"
        />
    </div>
  </div>
</template>

<script>

import { emailSubscriptionAPI } from "@/api/emailSubscription";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "EmailSubscriptionForm",
  components : {
    ValidationMessage, FeedbackNotification
  }, 
  props: ["handleResetForm", "searchTerm", "isRemote", "companyName", "contractType", "tags", "location", "minSalary"],
  setup () {
    const state = reactive({
      emailSubscriptionForm: {
        emailAddress : '',
        frequencyType : 'Daily'
        }
      });
      const rules = computed(() => {
        return {
          emailSubscriptionForm: {
            emailAddress: { required, email },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading : false, 
      notification : {
        text : "",
        level : "",
      },
      resetForm : this.handleResetForm,
      isEmailSubscriptionFormSubmitted : false,
      emailSubscription :{
        frequencyTypes : ['Daily', 'Weekly', 'Monthly']
      },
      search: { 
        searchTerm: this.searchTerm,
        isRemote: this.isRemote == null ? false : this.isRemote,
        companyName: this.companyName,
        tags: this.tags,
        minSalary: this.minSalary,
        location: this.location,
        contractType: this.contractType
      },
    };
  },
  watch: {
    handleResetForm(e) {
        //alert(this.handleResetForm)
        this.isEmailSubscriptionFormSubmitted = false;
        this.notification.text = '';
        this.notification.level = '';
    }
  },
  mounted() {
      //alert(this.resetForm)
  },
  methods: {
    async handleEmailSubscription() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await emailSubscriptionAPI.create(
              this.state.emailSubscriptionForm.emailAddress, 
              this.tags, 
              this.companyName, 
              this.minSalary,
              this.location,
              this.isRemote,
              this.state.emailSubscriptionForm.frequencyType,
              this.contractType,
              this.searchTerm);
            if (result.data)
            {
              this.notification = { text : "Subscription Added", level : "success" };
              this.isEmailSubscriptionFormSubmitted = true;
              this.resetForm = false;
            }
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };        
        }    
      }
    },
  },
};
</script>