<template>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <div v-if="!isRegisterFormSubmitted">
      <p>
        <input class="formInput" v-model="state.registerForm.companyName" placeholder="your company name" /><br>
        <ValidationMessage v-if="v$.registerForm.companyName.$error" :validationMessage ="v$.registerForm.companyName.$errors[0].$message"/>
      </p>
      <p>
        <input class="formInput" v-model="state.registerForm.emailAddress" placeholder="email address" /><br>
        <ValidationMessage v-if="v$.registerForm.emailAddress.$error" :validationMessage ="v$.registerForm.emailAddress.$errors[0].$message"/>
      </p>
      <p>
        <input class="formInput" v-model="state.registerForm.password.password" placeholder="password" type="password" /><br>
        <ValidationMessage v-if="v$.registerForm.password.password.$error" :validationMessage ="v$.registerForm.password.password.$errors[0].$message"/>
      </p>
      <p>
        <input class="formInput" v-model="state.registerForm.password.confirm" placeholder="confirm password" type="password" /><br>
        <ValidationMessage v-if="v$.registerForm.password.confirm.$error" :validationMessage ="v$.registerForm.password.confirm.$errors[0].$message"/>
      </p>
      <p>
        <button class="btn" v-on:click="handleRegisterCompany">Register</button>
      </p>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>  
  </div>
</template>

<script>

import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components : {
    FeedbackNotification, ValidationMessage
  }, 
  setup () {
    const state = reactive({
      registerForm: {
        companyName: "",
        emailAddress: "",
        password: {
          password: "",
          confirm: "",
        }
      }
    });
    const rules = computed(() => {
      return {
        registerForm: {
          companyName:  { required, minLength: minLength(3) },
          emailAddress: { required, email },
          password: {
            password:  { required, minLength: minLength(8) },
            confirm: { required, sameAs: sameAs(state.registerForm.password.password) },
          }
        }
      }
    })
    const v$ = useVuelidate(rules, state)
    return { state, v$ }
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isRegisterFormSubmitted: false
    };
  },
  methods: {
    async handleRegisterCompany() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await companyAPI.register(this.state.registerForm.companyName, 
              this.state.registerForm.emailAddress, this.state.registerForm.password.password);
              // success       
              this.isRegisterFormSubmitted = true;
              this.notification = { text : "Company Registered. Please check your email and click the confirmation link.", level : "success" };  
              // notify the parent view  
              this.$emit('formsubmitted');
            })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };      
        }
      }
    }
  },
};
</script>