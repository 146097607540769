<template>
  <div data-test-key="terms-and-privacy-page">
    <h1>Terms and Privacy</h1>
    <p>static page</p>
  </div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = 'Terms - TheJobTap.com'
  }
};
</script>