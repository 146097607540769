import { Order } from "@/domain/order/order";
import { httpClient } from "./http-client";
const basePath = "/order/";

export const orderAPI = {
  get: async () => 
  {
    // authenticated
    const url = basePath + "get/";
    const response = await httpClient.get(url);
    return response.data as Order;
  },
  createCustomerPortalSession: async () => 
  {
    // authenticated
    const url = basePath + "createcustomerportalsession/";
    const response = await httpClient.get(url);
    return response.data as string;
  },
}