import { JobListing } from "@/domain/jobListing/jobListing";
import { SearchJobListingsResult } from "@/domain/jobListing/searchJobListingsResult";
import { JobListingsForCompanyResult } from "@/domain/jobListing/jobListingsForCompanyResult";
import { httpClient } from "./http-client";
const basePath = "/job-listing";

export const jobListingAPI = {
  getByCompanyIdentifier: async () => 
  {
    // authenticated
    const url = basePath + "/getbycompanyidentifier";
    const response = await httpClient.get(url);
    return response.data as JobListingsForCompanyResult;
  },
  getByIdentifier: async (identifier: string) => 
  {
    const url = basePath + "/getbyidentifier?identifier=" + identifier;
    const response = await httpClient.get(url);
    return response.data as JobListing;
  },
  search: async (categoryName: string[], companyName: boolean, isRemoteOnly: string, 
    minSalary: number, location: string, contractType: string, searchTerm: string, sortOrder: string, pageSize: string, pageNumber: string) => 
  {
    var categoryNameQuery = ''; 
    if(Array.isArray(categoryName))
    {
      categoryName.forEach(element => {
        if (element != '')
        {
          categoryNameQuery = categoryNameQuery + "&categoryname=" + element;
        }
      });
    } 
    else
    {
      categoryNameQuery = "&categoryname=" + categoryName;
    }
    var companyNameQuery = companyName != null ? "&companyname=" + companyName : ''; 
    var locationQuery = location != null ? "&location=" + location : ''; 
    var contractTypeQuery = contractType != null ? "&contractType=" + contractType : ''; 
    var minSalaryQuery = minSalary != null ? "&minSalary=" + minSalary : ''; 
    var searchTermQuery = searchTerm != null ? "&searchTerm=" + searchTerm : ''; 
    var sortOrderQuery = sortOrder != null ? "&sortOrder=" + sortOrder : ''; 
    var pageSizeQuery = pageSize != null ? "&pageSize=" + pageSize : ''; 
    var pageNumberQuery = pageNumber != null ? "&pageNumber=" + pageNumber : ''; 

    const url = basePath + "/search/?" + 
    categoryNameQuery + 
    companyNameQuery +
    "&isRemote=" + isRemoteOnly + 
    minSalaryQuery + 
    locationQuery +
    contractTypeQuery +
    searchTermQuery +
    sortOrderQuery +
    pageSizeQuery +
    pageNumberQuery;

    const response = await httpClient.get(url);
    return response.data as SearchJobListingsResult;
  },
  create: async (title: string, skillsRequired: string[], hoursPerWeek: number, 
    experienceLevelRequiredType: string, location: string, attendanceRequirement: string, 
    description: string, contractType: string, 
    jobListingCategoryNames: string[], contractLengthInMonths: string,
    applicationType: string, applicationTypeValue: string, salaryFrom: number, salaryTo: number) => 
  {
    const url = basePath + "/create";
    return await httpClient.post(url, {
      Title: title,
      SkillsRequired: skillsRequired,
      HoursPerWeek: hoursPerWeek,
      ExperienceLevelRequiredType: experienceLevelRequiredType,
      Location: location,
      AttendanceRequirement: attendanceRequirement,
      Description: description,
      ContractType: contractType,
      Salary: 
      { 
        From: salaryFrom,
        To: salaryTo
      },
      JobListingCategoryNames: jobListingCategoryNames,
      ContractLengthInMonths: contractLengthInMonths,
      ApplicationType: applicationType,
      ApplicationTypeValue: applicationTypeValue,
    });
  },
  update: async (identifier: string, title: string, skillsRequired: string[], hoursPerWeek: number, 
    experienceLevelRequiredType: string, location: string, attendanceRequirement: string, 
    description: string, contractType: string, 
    jobListingCategoryNames: string[], contractLengthInMonths: string,
    applicationType: string, applicationTypeValue: string, salaryFrom: number, salaryTo: number) => 
  {
    const url = basePath + "/update";
    return await httpClient.post(url, {
      Identifier: identifier,
      Title: title,
      SkillsRequired: skillsRequired,
      HoursPerWeek: hoursPerWeek,
      ExperienceLevelRequiredType: experienceLevelRequiredType,
      Location: location,
      AttendanceRequirement: attendanceRequirement,
      Description: description,
      ContractType: contractType,
      Salary: 
      { 
        From: salaryFrom,
        To: salaryTo
      },
      JobListingCategoryNames: jobListingCategoryNames,
      ContractLengthInMonths: contractLengthInMonths,
      ApplicationType: applicationType,
      ApplicationTypeValue: applicationTypeValue,
    });  
  },
  delete: async (jobListingIdentifier: string) => 
  {
    const url = basePath + "/delete";
    return await httpClient.post(url, {
      Identifier: jobListingIdentifier,
    });
  },
  setActiveStatus: async (jobListingIdentifier: string) => 
  {
    const url = basePath + "/setactivestatus";
    return await httpClient.post(url, {
      Identifier: jobListingIdentifier,
    });
  },
  sendAsEmail: async (jobListingIdentifier: string, emailAddress: string) => 
  {
    const url = basePath + "/sendasemail";
    return await httpClient.post(url, {
      JobListingIdentifier: jobListingIdentifier,
      EmailAddress: emailAddress
    });
  },
};