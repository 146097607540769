<template>
<div data-test-key="company-login-page">
    <h1>Company Login</h1>
      <CompanyLoginForm @formsubmitted="e" />
      <br/>
      <router-link to="/company-reset-password/">Reset Password</router-link>
  </div>
</template>

<script>

import CompanyLoginForm from "@/components/Forms/CompanyLoginForm.vue";
import { mapGetters } from "vuex"; 

export default {  
  name: "CompanyLoginView",
  components : {
    CompanyLoginForm
  }, 
  data() {
    return {
    };
  },
 computed :{
    ...mapGetters(['companyUserStore'])
 },
 created() {
    if (this.companyUserStore != null)
    {
      this.$router.push('/company-dashboard/')        
    }
 },
  mounted() {
    document.title = 'Company Login - TheJobTap.com'
  },
  methods: {
    
  },
};
</script>