<template>
<div data-test-key="company-dashboard-page">
    <h1>Dashboard</h1>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else-if="!this.company.HasCompanyDetailSet">
        <!-- No Access until some company details filled in -->
      <SetCompanyDetailsForm @formsubmitted="e" />
    </div>
    <div v-else>
    <!-- Company user logged in  -->
      <p>Logged in as [<b>{{ companyUserStore.EmailAddress }}</b>]</p>
      <h2>{{ company.Name }}</h2>
      <!-- Company stats  -->
      <table v-if="this.jobListingStats" style="max-width: 400px; margin: auto;">
        <tr >
          <td style="width:160px; text-align: left;"><b>Active Listings:</b></td>
          <td v-if="this.order !== null" style="text-align: right;">{{ this.jobListingStats.resultCount }}/{{ this.order.AllowedJobListingsCount }}</td>
          <td v-else style="text-align: right;">0/0</td>
        </tr>
        <tr>
          <td style="width:160px; text-align: left;"><b>Application Clicks:</b></td>
          <td style="text-align: right;">{{ this.jobListingStats.totalApplicationClicks }}</td>
        </tr>
      </table>
      <!-- <p>Total Job Listing Page Views: {{ this.jobListingStats.resultCount }}</p> -->
      <!-- <p>Total Search Result appearances: {{ this.jobListingStats.totalViews }}</p> -->
      <!-- <p>Total Company Page Views: {{ this.jobListingStats.resultCount }}</p> -->
      <!-- <p>Total Company Website Clicks: {{ this.jobListingStats.resultCount }}</p> -->
      <div class="jobs">
        <h2>Job Listings</h2>
        <button data-test-key="button-create-job-listing" style="padding:10px"  v-on:click="handleCreateJobClick()">Create Job Listing</button>
        
        <FeedbackNotification v-if="this.notification.text != ''" 
          :feedbackText="this.notification.text" 
          :feedbackLevel="this.notification.level"/> 

        <div v-if="this.jobListings.length > 0">
          <table style="margin-left: auto; margin-right: auto; margin-top: 15px; padding: 10px;">
            <tr>
              <td style="width:350px; font-weight: bold; text-align: left;">Position</td>
              <td style="width:120px; font-weight: bold;">Applicants</td>
              <td style="width:90px; font-weight: bold;">Active</td>
              <td></td>
            </tr>
            <tr v-bind:key="jobListing.Identifier" v-for="jobListing in jobListings">
              <td style="text-align: left;">
                <a v-on:click="viewJobListingPageClick(jobListing.CompanyName, jobListing.Identifier, jobListing.IsActive)">
                  {{ jobListing.Title }}
                </a>
              </td>
              <td>{{ jobListing.ApplicationCount }}</td>
              <td>
                <input v-on:click="handleSetActiveStatusClick(jobListing.Identifier)" type="checkbox" id="checkbox" v-model="jobListing.IsActive">
              </td>
              <td>
                <button data-test-key="button-manage-job-listing" class="btn" v-on:click="handleManageJobListingClick(jobListing.Identifier)">manage</button>
                <button class="btn" v-on:click="handleDeleteJobListingClick(jobListing.Identifier)">delete</button>
              </td>
            </tr>
          </table>
        </div>
        </div>
      </div>
    </div>
</template>

<script>

import SetCompanyDetailsForm from "@/components/Forms/SetCompanyDetailsForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { companyAPI } from "@/api/company";
import { orderAPI } from "@/api/order";
import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{  
  name: "CompanyAccountView",
  components : {
    SetCompanyDetailsForm, FeedbackNotification
  },  
  data() {
    return {
      pageLoading : true,
      jobListings : [],
      company : null,
      order : null,
      jobListingStats :{
        resultCount : null,
        totalApplicationClicks : null,
        totalViews : null,
      },
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    if (this.companyUserStore == null)
    {
      this.$router.push('/');        
    }
    else
    {
      this.getCompany();
      this.getActiveOrder();
      this.getJobListings();
    }
 },
  mounted() {
    document.title = 'Dashboard - TheJobTap.com'
  },
 computed: {
    ...mapGetters(['companyUserStore'])
 },
  methods: {
    async viewJobListingPageClick(companyName, identifier, isActive) {
      if(isActive)
      {
        this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
      } 
      else
      {
        alert("not active");
      }
    },
    async handleCreateJobClick(){
      this.$router.push('/company-account/job-listing/');
    },
    handleManageJobListingClick(jobListingIdentifier){
      this.$router.push('/company-account/job-listing/' + jobListingIdentifier);
    },
    async handleDeleteJobListingClick(jobListingIdentifier) {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.delete(jobListingIdentifier);
          this.notification = { text : "Job listing deleted", level : "success" };           
          await this.getJobListings();
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }
    },
    async handleSetActiveStatusClick(jobListingIdentifier) {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.setActiveStatus(jobListingIdentifier);
          this.notification = { text : "Updated job listing active status", level : "success" };           
          await this.getJobListings();
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }
    },
    async getCompany() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.get();
          this.company = result;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }
    },
    async getActiveOrder() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await orderAPI.get();
          this.order = result;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }
    },
    async getJobListings() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.getByCompanyIdentifier();
          this.jobListings = result.Results;
          if(this.jobListings == "")
          {
            this.notification = { text : "No jobs listed", level : "notification" };           
          }
          this.jobListingStats.resultCount = result.ResultCount;
          this.jobListingStats.totalApplicationClicks = result.TotalApplicationClicks;
          this.jobListingStats.totalViews = result.TotalViews;
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }
    }
  },
};
</script>