<template>
<div data-test-key="billing-history-page">
  <h1>Invoice History</h1>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>  
    </div>
    <div v-if="this.invoiceRecords != ''">
      <h2>Recent Activity</h2>
      <table style="margin-left: auto; margin-right: auto; padding: 10px;">
        <tr>
            <td style="width: 150px; font-weight: bold; text-align: left;">Type</td>
            <td style="width: 150px; font-weight: bold;">Status</td>
            <td style="width: 90px; font-weight: bold;">Amount</td>
            <td style="width: 220px; font-weight: bold;">Date</td>
        </tr>
        <tr v-bind:key="invoiceRecord.Identifier" v-for="invoiceRecord in invoiceRecords">
            <td style="text-align: left;">{{invoiceRecord.ProductType}}</td>
            <td>{{invoiceRecord.InvoiceStatus}}</td>
            <td>{{invoiceRecord.Amount.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})}}</td>
            <td>{{invoiceRecord.DateCreated}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { invoiceHistoryAPI } from "@/api/invoiceHistory";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{  
  name: "CompanyInvoiceHistoryView",
  components : {
    FeedbackNotification
  },  
  data() {
    return {
      invoiceRecords : [],
      pageLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    if (this.companyUserStore == null)
    {
      this.$router.push('/');        
    }
    else
    {
      this.getInvoiceRecords();
    }
 },
  mounted() {
    document.title = 'Invoice History - TheJobTap.com'
  },
 computed: {
    ...mapGetters(['companyUserStore'])
 },
  methods: {
    async getInvoiceRecords(){
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await invoiceHistoryAPI.getInvoiceRecords();
          this.invoiceRecords = result;
          if(this.invoiceRecords == ''){
            this.notification = { text : "No invoice records found", level : "notification" };              
          }
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };              
      }
    }
  },
};
</script>