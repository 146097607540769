<template>
  <div data-test-key="advertise-page">
    <h1>Advertise with us</h1>

    <p>Hooking job seekers with simplicity and savings, JobSite is the new hub for hassle-free hiring. 
      By streamlining the recruitment process and reducing costs, JobSite makes advertising open positions easy and effective. 
      With an intuitive interface and wide reach, employers can post jobs knowing quality candidates will take notice. 
      Since launching in 20XX, JobSite has facilitated over X,XXX successful hires, establishing itself as the go-to site for attracting top talent. 
      With affordable packages and a commitment to generating quality leads, JobSite takes the pain out of recruitment.</p>

    <h2>Mission</h2>
    <p>Make things as simple as they can be</p>
    <p>Our goal is to get you quality leads/applications</p>
    <p>Specifc for niche recruitment.. a big flaw is not finding the right talent </p>
    <p>Specialising in a niche and focusing on one sector to bring you the talent you want to attract</p>

    <h2>Subscriptions</h2>
    <p>Subscription levels designed to enuser quality cheaper option for startups looking for quality devs</p>
    <p>1 job subscription level for super cheap rate</p>
    <p>Show Promo Vid..</p>
    <p>Watch this short 2 minute video to view you account area..</p>
    <p>Show subscription levels</p>

    <h2>Job distrobution coverage</h2>
    <p>Deployed to web, mobile app stores</p>
    <p>Take advantage of our job distribtuoin network, google jobs, emails, advertisements, telegram </p>
    <p>Email sub list -> site visitors can subscribe to any level of the search allowing us to send very relevant jobs to ensure a high CTR from email subscriptions</p>
    <p>Targeting of niche ads to taleted devs for good application conversions</p>
  </div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = 'Advertise - TheJobTap.com'
  }
};
</script>