<template>
  <h2>Reset Password</h2>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <div v-if="!this.isFormSubmitted">
      <p>
        <input class="formInput" v-model="state.processResetPasswordForm.password.newPassword" placeholder="password" type="password" /><br/>
        <ValidationMessage v-if="v$.processResetPasswordForm.password.newPassword.$error" :validationMessage ="v$.processResetPasswordForm.password.newPassword.$errors[0].$message"/>
      </p>
      <p>
        <input class="formInput" v-model="state.processResetPasswordForm.password.confirm" placeholder="confirm password" type="password" /><br/>
        <ValidationMessage v-if="v$.processResetPasswordForm.password.confirm.$error" :validationMessage ="v$.processResetPasswordForm.password.confirm.$errors[0].$message"/>
      </p>
      <button class="btn" v-on:click="processResetPasswordHandler">Reset Password</button>
    </div>
     <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>
</div>
</template>

<script>
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default  {
  components : {
    ValidationMessage, FeedbackNotification
  }, 
  setup () {
    const state = reactive({
      processResetPasswordForm: {
            password: {
                newPassword: "",
                confirm: ""
            }
        },
    });
    const rules = computed(() => {
      return {
        processResetPasswordForm: {
          password: {
            newPassword:  { required, minLength: minLength(8) },
            confirm: { required, sameAs: sameAs(state.processResetPasswordForm.password.newPassword) },
          }
        }
      }
    })
    const v$ = useVuelidate(rules, state)
    return { state, v$ }
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isFormSubmitted : false,
      token : this.$route.params.token,
    };
  },
  methods: {
    async processResetPasswordHandler()
    {
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
            const result = await companyUserAPI.processResetPassword(this.token, this.state.processResetPasswordForm.password.newPassword);
            this.notification = { text : "Processed Reset Password", level : "success" };  
            this.$emit('formsubmitted', 'Processed Reset Password');  
        }));
        this.pageLoading = false;
        if (!result.success) 
        {
          this.notification = { text : result.data, level : "error" };
          this.$emit('formsubmitted', 'Unable to process Processed Reset Password' + " [" + result.data.response.data.Data + "]");
        }
      }
    },
  },
};
</script>