<template>
<div data-test-key="company-register-page">
    <h1>Create an account</h1>
      <CompanyRegisterForm @formsubmitted="e" />
  </div>
</template>

<script>

import CompanyRegisterForm from "@/components/Forms/CompanyRegisterForm.vue";
import { mapGetters } from "vuex"; 

export default {  
  name: "CompanyRegisterView",
  components : {
    CompanyRegisterForm
  }, 
  data() {
    return {
    };
  },
 computed :{
    ...mapGetters(['companyUserStore'])
 },
 created() {
    if (this.companyUserStore != null)
    {
      this.$router.push('/company-account/')        
    }
 },
  mounted() {
    document.title = 'Register Company - TheJobTap.com'
  },
  methods: {
  },
};
</script>