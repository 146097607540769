import { httpClient } from "./http-client";
const basePath = "/site";

export const siteAPI = {
  submitContactUsRequest: async (emailAddress: string, contactText: string) => 
  {
    const url = basePath + "/submitcontactusrequest";
    return await httpClient.post(url, {
        Emailaddress: emailAddress,
        ContactText: contactText,
    });
  },
  getSiteMap: async () => 
  {
    const url = basePath + "/sitemap";
    return await httpClient.get(url);
  },
};