<template>
<div data-test-key="company-reset-password-page">
    <h1>Company Reset Password</h1>
      <CompanyPasswordResetRequestForm @formsubmitted="e" />
  </div>
</template>

<script>

import CompanyPasswordResetRequestForm from "@/components/Forms/CompanyPasswordResetRequestForm.vue";
import { mapGetters } from "vuex"; 

export default {  
  name: "CompanyResetPasswordView",
  components : {
    CompanyPasswordResetRequestForm
  }, 
 computed :{
    ...mapGetters(['companyUserStore'])
 },
 created() {
    if (this.companyUserStore != null)
    {
      this.$router.push('/company-account/')        
    }
 },
  mounted() {
    document.title = 'Reset Password - TheJobTap.com'
  }
};
</script>