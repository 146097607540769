<template>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <p>
        <label>Send to email address</label><br/>
        <input class="formInputLarge" v-model="state.emailForm.emailAddress" placeholder="Email Address" />      
        <button class="btn" v-on:click="emailJobListingHandler()">Send</button>
        <br/>
        <ValidationMessage v-if="v$.emailForm.$error" :validationMessage ="v$.emailForm.$errors[0].$message" />
      </p>
      <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
    </div>
</template>

<script>
import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  props: ["jobListingIdentifier"],
  components: { ValidationMessage, FeedbackNotification },
  setup () {
    const state = reactive({
      emailForm : {
        emailAddress : ''
        },
      });
      const rules = computed(() => {
        return {
          emailForm: {
            emailAddress : { required, email },
          }
        }})
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      pageLoading: false, 
    };
  },
  mounted(){

  },
  methods: {
    async emailJobListingHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await jobListingAPI.sendAsEmail(
              this.state.emailForm.emailAddress, 
              this.jobListingIdentifier
              );
              // success   
              if(result.data)
              {
                this.notification.text = "Job listing sent";
                this.notification.level = "success"
                this.isEmailJobListingFormSubmitted = true; 
                // notify the parent view  
                this.$emit('formsubmitted');        
              }    
            })
          );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
      }
    },
  },
};
</script>