

import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex"; 
import router from "@/router";
import store from "@/store/vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "Navigation",
 computed :{
    ...mapGetters(['companyUserStore'])
 },
    methods: {
    async handleLogout() {
      // remove from local storage
      localStorage.removeItem('jbpw-auth-token');
      // remove from store
      store.dispatch('companyUserStore', null)
      // destroy token
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.logout();
          if(result){
            router.push('/');
          }
        })
      );
      if (!result.success) {

      }  
    },
  }
}

