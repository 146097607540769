<template>
<div data-test-key="manage-subscription-page">
    <h1>Manage Subscription</h1>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else-if='this.activeOrder != null'>
       <h2>Active</h2>
       <FeedbackNotification v-if="this.notification.text != ''" 
         :feedbackText="this.notification.text" 
         :feedbackLevel="this.notification.level"/> 
         <!-- Active subscription -->
           <table style="margin-left: auto; margin-right: auto; padding: 20px;">
            <tr>
              <td style="padding: 5px; width:130px; text-align: left;"><b>Name:</b></td>
              <td style="text-align: left;">{{ activeOrder.SubscriptionName }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; width:130px; text-align: left;"><b>Listings:</b></td>
              <td style="text-align: left;">{{ activeOrder.AllowedJobListingsCount }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; width:130px; text-align: left;"><b>Billing Cycle:</b></td>
              <td style="text-align: left;">{{ activeOrder.IntervalCount }} x {{ activeOrder.Interval }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; width:130px; text-align: left;"><b>Amount:</b></td>
              <td style="text-align: left;">{{ activeOrder.AmountDue }}{{ activeOrder.Currency }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; width:130px; text-align: left;"><b>AutoRenew:</b></td>
              <td style="text-align: left;">{{ activeOrder.AutoRenew }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; width:130px; text-align: left;"><b>Valid From:</b></td>
              <td style="text-align: left;">{{ activeOrder.ValidFrom }}</td>
            </tr>
            <tr>
              <td style="padding: 5px; width:130px; text-align: left;"><b>Valid Until:</b></td>
              <td style="text-align: left;">{{ activeOrder.ValidUntil }}</td>
            </tr>
          </table>
          <!-- Manage subscription -->
          <button type="submit" v-on:click="createCustomerPortalSession">Manage subscription</button>
    </div>
    <div v-else>
      <h2>Subscriptions</h2>
      <!-- New Subscription -->
      <stripe-pricing-table 
        pricing-table-id="prctbl_1MahSrHWWEZrUPkfMFlq4Qab"
        publishable-key="pk_test_51Ljo6qHWWEZrUPkftD5oD1mR84qZQ7cZMmYtZrmaa1Yd8HecDPd6FB3XlQ7yXIVWTGnzFAYXkdxxsUw2LoNsDLbk00AhYngOyd"
        :client-reference-id="company.Identifier"
        return-url="https://thejobtap.com/company-account/subscription/">
      </stripe-pricing-table>
    </div>
  </div>
</template>

<script>

import { companyAPI } from "@/api/company";
import { orderAPI } from "@/api/order";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{  
  name: "CompanySubscriptionView",
  components : {
    FeedbackNotification
  }, 
  data() {
    return {
      company : null,
      activeOrder : null,
      pageLoading : true,
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    if (this.companyUserStore == null)
    {
      this.$router.push('/');        
    }
    else
    {
      this.getActiveOrder();
    }
 },
 mounted() {
    document.title = 'Subscriptions - TheJobTap.com';
    let stripeScript = document.createElement('script')
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/pricing-table.js')
    document.head.appendChild(stripeScript)
  },
 computed: {
    ...mapGetters(['companyUserStore'])
 },
  methods: {
    async getActiveOrder() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await orderAPI.get();
          if(result != null)
          {
            this.activeOrder = result;
            if(!this.activeOrder.AutoRenew){
              this.notification = { text : "Job Listings will become inactive when your subscription expires", level : "notification" };           
            }
            this.pageLoading = false;
          }
          else
          {
            this.getCompany();
          }
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }
    },
    async getCompany() {
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await companyAPI.get();
            this.company = result;
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };           
        }
      },
    async createCustomerPortalSession() {
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await orderAPI.createCustomerPortalSession();
            // redirect to url
            window.location.href = result;
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };           
        }
      },
    }
};
</script>