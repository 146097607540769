<template>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <div v-if="!isResetPasswordFormSubmitted">
      <p>
        <input class="formInput" v-model="state.resetPasswordForm.emailAddress" placeholder="emailAddress" /><br/>
        <ValidationMessage v-if="v$.resetPasswordForm.emailAddress.$error" :validationMessage ="v$.resetPasswordForm.emailAddress.$errors[0].$message" />
      </p>
      <button class="btn" v-on:click="handleResetPasswordRequest()">Reqest a reset link</button>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components : {
    FeedbackNotification, ValidationMessage
  }, 
  setup () {
    const state = reactive({
      resetPasswordForm: {
        emailAddress: "",
      }
    });
    const rules = computed(() => {
      return {
        resetPasswordForm: {
            emailAddress: { required, email },
        }
      }
    })
    const v$ = useVuelidate(rules, state)
    return { state, v$ }
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isResetPasswordFormSubmitted: false
    };
  },
  methods: {
    async handleResetPasswordRequest() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
       const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.requestPasswordReset(this.state.resetPasswordForm.emailAddress);        
          this.notification = { text : "Check your email for a reset link", level : "success" };  
          this.isResetPasswordFormSubmitted = true;
          // notify the parent view  
          this.$emit('formsubmitted');
        }));
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
      }
    }
  },
};
</script>