<template>
    <h2>Update Company Details</h2>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <p>
        <input class="formInput" v-model="state.updateCompanyForm.name" placeholder="name" /><br/>
        <ValidationMessage v-if="v$.updateCompanyForm.name.$error" :validationMessage ="v$.updateCompanyForm.name.$errors[0].$message" />
      </p>
      <p>
        <input class="formInput" v-model="state.updateCompanyForm.website" placeholder="website" /><br/>
        <ValidationMessage v-if="v$.updateCompanyForm.website.$error" :validationMessage ="v$.updateCompanyForm.website.$errors[0].$message" />
      </p>
      <p>
        <WysiwygEditor v-if="state.updateCompanyForm.description != ''" 
          :editorText="state.updateCompanyForm.description"
          @onChange="updateCompanyDescription" />
          <!-- <span>{{this.state.updateCompanyForm.description}}</span> -->
          <input type="hidden" v-model="state.updateCompanyForm.description" /><br/>
          <ValidationMessage v-if="v$.updateCompanyForm.description.$error" :validationMessage ="v$.updateCompanyForm.description.$errors[0].$message" />
      </p>
      <button class="btn" v-on:click="updateCompanyHandler">Update Company</button>
      <FeedbackNotification v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import WysiwygEditor from '@/components/WysiwygEditor.vue'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "UpdateCompanyForm",
  components: { WysiwygEditor, ValidationMessage, FeedbackNotification },
  props: ["company"],
  emits: ["formsubmitted"],
  setup () {
    const state = reactive({
      updateCompanyForm: {
        name : '', 
        description: '',
        website : ''
        }
      });
      const rules = computed(() => {
        return {
          updateCompanyForm: {
            name : { required, },
            description: { required, },
            website: { required, }
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  mounted(){
    this.setFormState();
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      }   
    };
  },
  methods: {
    updateCompanyDescription(e){
      this.state.updateCompanyForm.description = e;
    },
    async updateCompanyHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = 
            await companyAPI.update(this.state.updateCompanyForm.name, 
              this.state.updateCompanyForm.website, this.state.updateCompanyForm.description);
              // success   
              if(result.data)
              {
                this.notification = { text : "Company updated", level : "success" };      
                // notify the parent view  
                this.$emit('formsubmitted', 'Company updated');  
              } 
          }));
        if (!result.success) 
        {
          this.pageLoading = false;          
          this.notification = { text : result.data, level : "error" };        
        }
      }
    },
    async setFormState() {
      this.state.updateCompanyForm.name = this.company.Name;
      this.state.updateCompanyForm.description = this.company.Description;
      this.state.updateCompanyForm.website = this.company.Website;
    },
  },
};
</script>