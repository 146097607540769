<template>
  <div data-test-key="job-listing-page">
    <FeedbackNotification style="margin-bottom: 15px;" 
      v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
    <div v-if="jobPageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else-if="jobListing != null && this.company != null">
      <!-- Breadcrumb -->
      <div class="_divBreadcrumb">
        <p>
          <a href="#" v-on:click="clickHiringCompaniesPageLink">Hiring Companies</a> /
          <a href="#" v-on:click="clickCompanyPageLink">{{company.Name}}</a> /
          {{jobListing.Title}}
        </p>
      </div>
      <h1>{{jobListing.Title}}</h1>
      <div>
        <!-- Company: Image -->
        <img :src=company.ImageLogoUrl style="height: 70px;" />
        <p><b>{{company.Name}}</b></p>
        <!-- Job Listing: Details -->
        <p><b>Position:</b> {{ jobListing.Title }}</p>
        <p><b>Salary:</b> {{ jobListing.Salary.From.toLocaleString('en-US', { style: 'currency',currency: 'USD'}) }} - {{ jobListing.Salary.To.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0}) }}</p>
        <p><b>Hours pw:</b> {{jobListing.HoursPerWeek}}</p>
        <p><b>Location:</b> {{jobListing.Location}}</p>
        <p><b>Experience level:</b> {{jobListing.ExperienceRequiredType}}</p>
        <p><b>Attendance requirement:</b> {{jobListing.AttendanceRequirement}}</p>
        <p><b>Contract type:</b> {{jobListing.ContractType}}</p>
        <p><b>Applicants:</b> {{jobListing.ApplicationCount}}</p>
        <p v-if="jobListing.ContractType == 'Contract'"><b>Contract Length (months):</b> {{jobListing.ContractLengthInMonths}}</p>
        <!-- Job Listing: Tags -->
        <div style="line-height: 25pt; min-width:450px; display:inline; overflow: hidden;">
          <li style="display:inline-block;" v-bind:key="categoryName" v-for="categoryName in jobListing.CategoryNames" >
            <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px;">
                <a>{{ categoryName }}</a>
            </span>
          </li>
        </div>
        <p><b>Posted on:</b> {{jobListing.DateCreated}}</p>
        <p v-if="jobListing.DateUpdated"><b>Last modified:</b> {{jobListing.DateUpdated}}</p>
        <!-- Apply button -->
        <JobListingApplyButton style="margin-bottom: 15px;" 
            :applicationType="jobListing.ApplicationType" 
            :ApplicationTypeValue="jobListing.ApplicationTypeValue"
            :jobListingTitle="jobListing.Title"
            :jobListingIdentifier="jobListing.Identifier"/>
            <br/>
        <!-- Share: Social media -->
        <p><b>Share links: </b>
          <ShareNetwork class="_linkShare"
              :popup="{width: 400, height: 200}" 
              network="facebook"
              :url=this.pageUrl
              :title=this.jobListing.Title
              :description=this.jobListing.Title
              quote="quote me"
              hashtags="jobs,it">
              <font-awesome-icon title="facebook" icon="fa-brands fa-facebook" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="twitter"
              :url=this.pageUrl
              :title=this.jobListing.Title
              :description=this.jobListing.Title
              twitter-user="TheJobTap">
              <font-awesome-icon title="twitter" icon="fa-brands fa-twitter" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="whatsapp"
              :url=this.pageUrl
              :title=this.jobListing.Title
              :description=this.jobListing.Title>
              <font-awesome-icon title="whatsapp" icon="fa-brands fa-whatsapp" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="telegram"
              :url=this.pageUrl
              :title=this.jobListing.Title
              :description=this.jobListing.Title>
              <font-awesome-icon title="telegram" icon="fa-brands fa-telegram" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="reddit"
              :url=this.pageUrl
              :title=this.jobListing.Title
              :description=this.jobListing.Title>
              <font-awesome-icon title="reddit" icon="fa-brands fa-reddit" />
          </ShareNetwork>
          <ShareNetwork class="_linkShare"
              network="linkedin"
              :url=this.pageUrl
              :title=this.jobListing.Title
              :description=this.jobListing.Title>
              <font-awesome-icon title="linkedin" icon="fa-brands fa-linkedin" />
          </ShareNetwork>
        </p>
        <!-- Send by email -->
        <p><button style="cursor: pointer;" v-on:click="emailJobListingForm">Email me this job</button></p>
        <EmailJobListingForm v-if="this.display.emailForm"
            :jobListingIdentifier="jobListing.Identifier"/>
        <button style="cursor: pointer;" v-on:click="clickRelatedJobSearchLink">view more jobs like this</button>
        <br/>
        <!-- Job Listing: Description  -->
        <WysiwygViewer v-if="this.jobListing != ''" :viewerText="this.jobListing.Description" />
      </div>
    </div>
  </div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import JobListingApplyButton from "@/components/JobListingApplyButton.vue";
import EmailJobListingForm from "@/components/Forms/EmailJobListingForm.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { WysiwygViewer, FeedbackNotification, JobListingApplyButton, EmailJobListingForm },
  data() {
    return {
      pageUrl: "",
      company: null,
      jobListing : null,
      jobPageLoading : true,      
      notification : {
        text : "",
        level : "",
      },
      display: {
        emailForm : false
      }
    };
  },
  created() {
    this.loadPage();
  },
  mounted() {

  },
  methods: {
    async loadPage() {
      this.getCompanyByName();
      this.pageUrl = window.location.href;
    },
    async clickCompanyPageLink () {
      this.$router.push('/hiring-companies/' + this.company.Name + '/');
    },
    async clickHiringCompaniesPageLink() {
      this.$router.push('/hiring-companies/');
    },
    async clickRelatedJobSearchLink () {
      let querySearch = "?tags=" + this.jobListing.CategoryNames.map(entry => entry).join('&tags=');
      if (this.jobListing.AttendanceRequirement == "Remote"){
        querySearch = querySearch + "&isRemote=true";
      }  
      querySearch = querySearch + "&sortOrder=BestMatch";

      this.$router.push('/' + querySearch);
    },
    async emailJobListingForm() {
      this.display.emailForm = !this.display.emailForm;
    },
    async getCompanyByName() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.getByName(this.$route.params.companyname);
          this.company = result;
        })
      );
      if (!result.success) 
      {
        this.jobPageLoading = false;
        this.notification = { text : result.data, level : "error" };        
      } 
      else
      {
        this.getJobListingByIdentifier();
      }
    },
    async getJobListingByIdentifier() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.getByIdentifier(this.$route.params.identifier);
          this.jobListing = result;
        })
      );
      this.jobPageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
      else
      {
        document.title = this.jobListing.Title + ' by ' + this.company.Name + ' - TheJobTap.com';
      }
    },
  },
};
</script>