<template>
  <h2>Company Logo</h2>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
      <div id="preview">
        <img v-if="company !== ''" :src=imagePreviewUrl style="height:70px;" />
      </div>
      <p>
        <input class="formInput" @change=handleCompanyImageUploadToBrowser type="file" name="fileinnertoupload"/>
        <input v-model="state.uploadCompanyImageForm.imageFile" type="hidden"/>
      </p>
      <p>
        <ValidationMessage v-if="v$.uploadCompanyImageForm.imageFile.$error" :validationMessage ="v$.uploadCompanyImageForm.imageFile.$errors[0].$message"/>
      </p>
      <button class="btn" v-on:click="handleCompanyLogoUpload">Update Image</button>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>
  
  <script>
  import { companyAPI } from "@/api/company";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import ValidationMessage from "@/components/ValidationMessage.vue";

  import { reactive, computed } from 'vue' 
  import useVuelidate from '@vuelidate/core'
  import { required } from '@vuelidate/validators'

  const pageStatus = useStatus();
  const log = useErrorLogger();

  export default {  
    name: "CompanyImageForm",  
    components : {
      FeedbackNotification, ValidationMessage
    }, 
    props: ["company"],
    emits: ["formsubmitted"],
    setup () {
    const state = reactive({
      uploadCompanyImageForm: {
        imageFile : '',
          }
      });
      const rules = computed(() => {
        return {
          uploadCompanyImageForm: {
            imageFile: { required },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  created(){
    this.setFormState();
  },
    data() {
      return {
        pageLoading : false,
        notification : {
          text : "",
          level : "",
        },
        imagePreviewUrl : null,
      };
    },
    methods: {
      async handleCompanyImageUploadToBrowser(e) {
        const file = e.target.files[0];
        this.imagePreviewUrl = URL.createObjectURL(file)
        this.state.uploadCompanyImageForm.imageFile = file;
      },
      async handleCompanyLogoUpload() {
        // validate the form
        this.v$.$validate();
        if(!this.v$.$error)
        {       
          this.pageLoading = true;
          const reader = new FileReader();
          reader.readAsDataURL(this.state.uploadCompanyImageForm.imageFile);
          reader.onload = async () => {
            var imageBytes = reader.result.split(',')[1];
            const result = await log.catchPromise(() =>
                pageStatus.observe(async () => {
                  const result = await companyAPI.uploadCompanyLogo(imageBytes);
                  // success   
                  if(result.data)
                  {
                    this.notification = { text : "Image updated", level : "success" };  
                    // notify the parent view  
                    this.$emit('formsubmitted');
                  } 
                }));
              if (!result.success) 
              {
                this.pageLoading = false;
                this.notification = { text : result.data, level : "error" };
              }
            }
         };
      },
    async setFormState() {
      this.imagePreviewUrl = this.company.ImageLogoUrl;
    },
    },
  };
  </script>