<template>
  <h2>Set Company Notification Email</h2>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <p>Set your email notification for company and job application notifications</p>
    <div v-if="!isSetNotificationEmailFormSubmitted">
        <p>
          <input class="formInput" v-model="state.setNotificationEmailForm.emailAddress" placeholder="emailAddress" /><br/>
          <ValidationMessage v-if="v$.setNotificationEmailForm.emailAddress.$error" :validationMessage ="v$.setNotificationEmailForm.emailAddress.$errors[0].$message" />
        </p>
      <button class="btn" v-on:click="setNotificationEmailHandler">Set Notification Email</button>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const pageStatus = useStatus();
const log = useErrorLogger();

export default {  
  name: "SetCompanyNotificationEmailAddressForm",
  components : {
    FeedbackNotification, ValidationMessage
  },
  props: ["company"],
  setup () {
    const state = reactive({
      setNotificationEmailForm: {
        emailAddress : '', 
        }
      });
      const rules = computed(() => {
        return {
          setNotificationEmailForm: {
            emailAddress : { required, email },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading: false,
      notification : {
        text : "",
        level : "",
      },
      isSetNotificationEmailFormSubmitted : false,
    };
  },
  created(){
    this.setFormState();
  },
  methods: {
    async setNotificationEmailHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {    
        this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = 
          await companyAPI.setNotificationEmail(this.state.setNotificationEmailForm.emailAddress);
            // success   
            if(result.data)
            {
              this.notification = { text : "Click the link in the email link to change the email address", level : "success" };      
              this.isSetNotificationEmailFormSubmitted = true;
            }
        }));
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };      
        }
      }
    },
   async setFormState() {
      this.state.setNotificationEmailForm.emailAddress = this.company.NotificationEmailAddress
    },
  },
};
</script>