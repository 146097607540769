<template>
  <div style="max-width: 800px; margin: auto;">
    <QuillEditor
      ref="myQuillEditor"
      theme = "snow"
      :content="content"
      :options="editorOption"
      v-on:blur="onEditorChange"
      v-on:keyup="onEditorChange"
      v-on:click="onEditorChange"
    />

  </div>
</template>

<script>
  import { QuillEditor } from '@vueup/vue-quill'
  import { quill } from 'quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import '@vueup/vue-quill/dist/vue-quill.bubble.css';
 
  export default {
  props: ["editorText"],
  components: {
    quill, QuillEditor
  },
  emits: ["onChange"],
  data () {
    return {
      editorOption: {
        placeholder: '...',
        theme: 'snow'        
      }
    }
  },
  watch: {
      content() {

      }
  },
    methods: {
      onEditorChange() {
        this.$emit('onChange', this.editor.getHTML())
        // console.log("editor")
        // console.log(this.editor)
        // console.log("delta")
        // console.log(this.editor.getContents())
        // console.log("getText")
        // console.log(this.editor.getText())
        // console.log("getHtml")
        // console.log(this.editor.getHTML())
      },
    },
    computed: {
      editor() {
       return this.$refs.myQuillEditor
      },
      content() {
       return this.content
      },
    },
    mounted() {
      //console.log('this is current quill instance object', this.editor)
        //this.content = this.editorText;
        if(this.editorText != ''){
          this.editor.setHTML(this.editorText)
        }
    }
  }
</script>