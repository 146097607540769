<template>
  <div data-test-key="home-page">
    <img src="https://imagedelivery.net/EWJlSj3VmuhdddwuuB6Q0A/d28c1138-dca0-4856-1072-88d1a0de7000/public" style="height: 130px; display:inline-block;">
        <!-- <p><i><b>empowering the web3 job space</b></i></p> -->
    <div>
      <h1 style="font-size:1.5em">Search Developer jobs...</h1>
      <!-- Search: Remote only checkbox -->
      Remote Only <input v-on:click="handleIsRemoteClick()" type="checkbox" id="checkbox" v-model="search.isRemote">
      <!-- Search: Salary selection -->
      <select style="margin-left:20px;" class="formInputSmall" @change="handleSalaryOnChange($event)" v-model="search.minSalary">
        <option disabled value=null>Min Salary</option>
        <option v-bind:key="salaryBracket" v-for="salaryBracket in salaryBrackets" :value="salaryBracket">{{ salaryBracket.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}+</option>
      </select>
      <br/>
      <!-- Search: Freetext input -->
      <input style="margin-top: 5px;" @keyup.enter="handleSearchTermInput()" class="formInput" v-model="search.searchTerm" placeholder="Search..."/>
      <!-- Search: Tags -->
      <div class="_divCategorySearch">
        <ul style="line-height: 30px; padding-inline-start: 0px">
          <li style="display:inline;">
            <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 5px; cursor: pointer;" 
              :class="{ active: this.search.tags == '' }"
              v-on:click="handleCategoryClick(null)"><a>All </a></span>
          </li>
          <li style="display:inline-block;" v-bind:key="jobListingCategory.Name" v-for="jobListingCategory in jobListingCategories" >
            <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;" 
              :class="{ active: search.tags.includes(jobListingCategory.Name) }" 
              v-on:click="handleCategoryClick(jobListingCategory.Name)"><a>{{ jobListingCategory.Name }} [{{ jobListingCategory.JobListingCount }}]</a></span>
          </li>
        </ul>
      </div>
      <!-- Search: Sort by -->
      <div v-if="search.location != '' || search.companyName != '' || search.contractType != ''" style="margin-bottom: 15px;">
        <span v-if="this.search.location != null" style="border: 1px solid blue; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;">
          <a v-on:click="handleLocationClick(null)">location: {{ this.search.location }} X</a>
        </span>
        <span v-if="this.search.companyName != null" style="border: 1px solid blue; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;">
          <a v-on:click="handleCompanyNameClick(null)">company: {{ this.search.companyName }} X</a>
        </span>
        <span v-if="this.search.contractType != null" style="border: 1px solid blue; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;">
          <a v-on:click="handleContractTypeClick(null)">contractType: {{ this.search.contractType }} X</a>
        </span>
      </div>
      <!-- Search: Meta info and Sort by -->
      <div style="margin-bottom: 15px;">Showing {{ this.jobListingShowingCount }} of {{ this.jobListingResultCount }} Jobs - 
        Sort by 
        <select v-model="search.sortOrder" style="padding: 5px; border:0px; outline:0px;" @change="handleSortOrderOnChange($event)">
          <option v-if="this.search.sortOrder == null" :value="null">{{ this.defaultSortOrder[1] }}</option>
          <option v-else :value="this.defaultSortOrder[0]">{{ this.defaultSortOrder[1] }}</option>
          <option v-bind:key="sortOrderType" v-for="sortOrderType in sortOrders" :value="sortOrderType[0]">{{ sortOrderType[1] }}</option>
        </select>
      </div>
    </div>
    <!-- Email Subscription Form -->
    <div style="margin-bottom: 15px;">
      <EmailSubscriptionForm 
        :handleResetForm="this.resetEmailForm"
        :searchTerm="this.search.searchTerm"
        :isRemote="this.search.isRemote"
        :companyName="this.search.companyName"
        :tags="this.search.tags"
        :location="this.search.location"
        :contractType="this.search.contractType"
        :minSalary="this.search.minSalary"
        ></EmailSubscriptionForm>
        <!-- @formsubmitted="e" -->
    </div>
    <!-- Page Feedback -->
    <FeedbackNotification style="margin-bottom: 15px;" 
      v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
    <!-- Job Listing Results -->
    <div v-if="jobListingsLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif" style="margin-bottom:800px"/>
    </div>
    <div v-else-if="this.jobListingResultCount !== 0">   
      <div style="padding: 15px;" v-bind:key="jobListing.Identifier" v-for="jobListing in jobListings">
        <div style="">
          <div style="cursor: pointer;"><a v-on:click="viewJobListingPageClick(jobListing.CompanyName, jobListing.Identifier)"><b>{{ jobListing.Title }}</b></a></div>
          <div style=""><a>{{ jobListing.Salary.From.toLocaleString('en-US', { style: 'currency',currency: 'USD'}) }} - {{ jobListing.Salary.To.toLocaleString('en-US', { style: 'currency',currency: 'USD'}) }}</a></div>
          <!-- <div style="font-style: italic;"><a>Applicants: {{ jobListing.ApplicationCount }}</a></div> -->
          <div style=""><a>Level: {{ jobListing.ExperienceRequiredType }}</a></div>
          <div style="">
            <a :class="{ activeText: search.companyName == jobListing.CompanyName }" style="cursor: pointer;" v-on:click="handleCompanyNameClick(jobListing.CompanyName)">{{ jobListing.CompanyName }}</a> | 
            <a :class="{ activeText: search.location == jobListing.Location }" style="cursor: pointer;" v-on:click="handleLocationClick(jobListing.Location)">{{ jobListing.Location }}</a> | 
            <a :class="{ activeText: search.contractType == jobListing.ContractType }" style="cursor: pointer;" v-on:click="handleContractTypeClick(jobListing.ContractType)">{{ jobListing.ContractType }}</a> | 
            <a :class="{ activeText: search.isRemote }">{{ jobListing.AttendanceRequirement }}</a>
          </div>
          <div style="line-height: 25pt; min-width:450px; display:inline; overflow: hidden;">
            <li style="display:inline-block;" v-bind:key="categoryName" v-for="categoryName in jobListing.CategoryNames" >
              <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;" 
                :class="{ active: search.tags.includes(categoryName) }" 
                v-on:click="handleCategoryClick(categoryName)">
                  <a>{{ categoryName }}</a>
                </span>
            </li>
          </div>
          <div>
            <button style="cursor: pointer;" @click.stop="viewJobListingPageClick(jobListing.CompanyName, jobListing.Identifier)">view</button>
            <button style="cursor: pointer;" @click.stop="handleJobListingShowMoreClick(jobListing.Identifier, this)">scan</button>
            <JobListingApplyButton 
              :applicationType="jobListing.ApplicationType" 
              :applicationTypeValue="jobListing.ApplicationTypeValue"
              :jobListingTitle="jobListing.Title"
              :jobListingIdentifier="jobListing.Identifier"/>
          </div>
        </div>
        <div v-if="showMoreJobListing != null && showMoreJobListing.Identifier == jobListing.Identifier">
          <WysiwygViewer :viewerText="this.showMoreJobListing.Description" />
        </div>
      </div>
      <Pagination
        :totalPages="Math.ceil(jobListingResultCount / pageSize)"
        :perPage="pageSize"
        :currentPage="currentPage"
        @pagechanged="onPageChange" />
    </div>
  </div>
</template>

<script>
import { jobListingAPI } from "@/api/jobListing";
import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import Pagination from '@/components/Pagination.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import JobListingApplyButton from "@/components/JobListingApplyButton.vue";
import EmailSubscriptionForm from "@/components/Forms/EmailSubscriptionForm.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {  
  name: "JobListingSearchView",
  components : {
    EmailSubscriptionForm, WysiwygViewer, FeedbackNotification, JobListingApplyButton, Pagination
  }, 
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      resetEmailForm: false,
      isApplicationSubmitted : false,
      search: { 
        searchTerm: this.$route.query.searchTerm !== undefined || this.$route.query.searchTerm != '' ? this.$route.query.searchTerm : null,
        isRemote: this.$route.query.isRemote !== undefined ? this.$route.query.isRemote : false,
        companyName: this.$route.query.companyName !== undefined ? this.$route.query.companyName : null,
        tags: this.$route.query.tags !== undefined ? this.$route.query.tags : [],
        minSalary: this.$route.query.minSalary !== undefined ? this.$route.query.minSalary : null,
        location: this.$route.query.location !== undefined ? this.$route.query.location : null,
        contractType: this.$route.query.contractType !== undefined ? this.$route.query.contractType : null,
        sortOrder: this.$route.query.sortOrder !== undefined ? this.$route.query.sortOrder : null,
        pageNumber: this.$route.query.pageNumber !== undefined ? this.$route.query.pageNumber : null,
        pageSize: this.$route.query.pageSize !== undefined ? this.$route.query.pageSize : null
      },
      jobListingResultCount : 0,
      jobListingShowingCount : 0,
      jobListings : [],
      jobListingsLoading : true,
      jobListingCategories : [],
      salaryBrackets : [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000],
      defaultSortOrder : ["BestMatch","Best Match"],
      sortOrders : [["RecentUpload","Recent Upload"],["HighestSalary","Highest Salary"],["LowestSalary","Lowest Salary"],["MostPopular","Most Popular"]],
      showMoreJobListing : null,
      currentPage: this.$route.query.pageNumber !== undefined ? this.$route.query.pageNumber : 1,
      pageSize: this.$route.query.pageSize !== undefined ? this.$route.query.pageSize : 50,
    };
  },
 created()
 {
    this.getJobListingCategories();
    this.handleJobListingSearch();   
 },
  mounted() {
    document.title = 'Search - TheJobTap.com'
  },
  methods: {
    async createQueryString() {
      const query = Object.assign({}, this.$route.query);
      query.sortOrder = this.search.sortOrder == null ? [] : this.search.sortOrder;
      query.searchTerm = this.search.searchTerm;
      query.tags = this.search.tags;
      query.companyName = this.search.companyName == null ? [] : this.search.companyName;
      query.isRemote = this.search.isRemote == false ? [] : this.search.isRemote;
      query.location = this.search.location == null ? [] : this.search.location;
      query.contractType = this.search.contractType == null ? [] : this.search.contractType;
      query.minSalary = this.search.minSalary == null ? [] : this.search.minSalary;
      query.pageNumber = this.search.pageNumber == null ? [] : this.search.pageNumber;
      query.pageSize = this.search.pageSize == null ? [] : this.search.pageSize;
      await this.$router.push({ query });
    },
    async onPageChange(pageNumber) {
      this.search.pageNumber = pageNumber;
      this.currentPage = pageNumber;
      this.jobListingShowingCount = pageNumber * this.pageSize;
      await this.handleJobListingSearch(false);
    },
    resetPageNumber(){
      this.search.pageNumber = this.search.pageSize;
      this.currentPage = 1;
    },
    async handleSearchTermInput() {
      await this.handleJobListingSearch(true);
    },
    async handleSortOrderOnChange(event){
      this.search.sortOrder = event.target.value;
      await this.handleJobListingSearch(true);
    },
    async handleLocationClick(location) {
      if(this.search.location == location){
        this.search.location = null;
      }
      else{
        this.search.location = location;
      }
      await this.handleJobListingSearch(true);
    },
    async handleCompanyNameClick(companyName) {
      if(this.search.companyName == companyName){
        this.search.companyName = null;
      }
      else{
        this.search.companyName = companyName;
      }
      await this.handleJobListingSearch(true);
    },
    async handleContractTypeClick(contractType) {
      if(this.search.contractType == contractType){
        this.search.contractType = null;
      }
      else{
        this.search.contractType = contractType;
      }
      await this.handleJobListingSearch(true);
    },
    async handleSalaryOnChange(event){
      this.search.minSalary = event.target.value;
      await this.handleJobListingSearch(true);
    },
    async handleIsRemoteClick() {
      this.search.isRemote = !this.search.isRemote;
      await this.handleJobListingSearch(true);
    },
    async handleCategoryClick(categoryName) {
      if (categoryName == null)
      {
         this.search.tags = [];
         await this.handleJobListingSearch(true);
         return;
      }
      if(!Array.isArray(this.search.tags))
      {
         this.search.tags = [categoryName];
         await this.handleJobListingSearch(true);
         return;
      }
      const categoryNames = [];
      this.search.tags.forEach(element => {
        if (element != categoryName)
        {
          categoryNames.push(element);
        }
      });
      if (!this.search.tags.includes(categoryName))
      {
        categoryNames.push(categoryName);
      }
      this.search.tags = categoryNames;
      await this.handleJobListingSearch(true);
    },
    async viewJobListingPageClick(companyName, identifier) {
      this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
    },
    async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories();
          this.jobListingCategories = result;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleJobListingSearch(resetPageNumber) 
    {
      if (resetPageNumber)
      {
        this.resetPageNumber();
      }
      this.jobListingsLoading = true;
      this.notification.text = "";
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.search(this.search.tags, this.search.companyName, 
          this.search.isRemote, this.search.minSalary, this.search.location, this.search.contractType, 
          this.search.searchTerm, this.search.sortOrder, this.pageSize, this.search.pageNumber);
              this.showMoreJobListing = null;
              this.jobListingResultCount = result.ResultCount;
              this.jobListingShowingCount = result.Results.length; 
              this.jobListings = result.Results;
              this.resetEmailForm = true;
              await this.createQueryString();
        })
      );
      this.jobListingsLoading = false;
      if (this.jobListingResultCount === 0)
      {
        this.notification = { text : "No job listing results found for search criteria", level : "notification" };        
      }
      if (!result.success) {
          this.jobListings = null;
          this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleJobListingShowMoreClick(identifier, e) {
      if (this.showMoreJobListing != null && this.showMoreJobListing.Identifier == identifier)
      {
        this.showMoreJobListing = null;
        return;
      }
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.getByIdentifier(identifier);
          this.showMoreJobListing = result;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
  },
};
</script>