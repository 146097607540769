<template>

<footer data-test-key="footer" class="footer">
    <router-link class="_footerLink" to="/about-us/">About</router-link> |
    <router-link class="_footerLink" to="/contact-us/">Contact</router-link> | 
    <router-link class="_footerLink" to="/policies/">Policies</router-link> |
    <router-link class="_footerLink" to="/sitemap/">SiteMap</router-link>
    <br/>
    <br/>
    <p>Landing pages:</p>
    <router-link class="_footerLink" to="/remote-jobs/">Remote Jobs</router-link> |
    <router-link class="_footerLink" to="/rust-jobs/">Rust Jobs</router-link> |
    <router-link class="_footerLink" to="/ai-jobs/">AI Jobs</router-link> |
    <router-link class="_footerLink" to="/user-experience-jobs/">UX Jobs</router-link>
</footer>

</template>

<style scoped>

</style>

<script lang="ts">


</script>