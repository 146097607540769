<template>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <div>
      <p>
        <input data-test-key="input-email" class="formInput" v-model="state.loginForm.emailAddress" placeholder="email address" /><br/>
        <ValidationMessage v-if="v$.loginForm.emailAddress.$error" :validationMessage ="v$.loginForm.emailAddress.$errors[0].$message" />
      </p>
      <p>
        <input data-test-key="input-password" class="formInput" v-model="state.loginForm.password" placeholder="password" type="password" /><br/>
        <ValidationMessage v-if="v$.loginForm.password.$error" :validationMessage ="v$.loginForm.password.$errors[0].$message"/>
      </p>
      <button data-test-key="button-login" class="btn" v-on:click="handleCompanyUserLogin">Login</button>
    </div>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
  </div>
</template>

<script>

import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { httpClient } from "@/api/http-client";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { mapGetters } from "vuex"; 

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components : {
    FeedbackNotification, ValidationMessage
  }, 
  setup () {
    const state = reactive({
      loginForm: {
        emailAddress: "",
        password: "",
      }
    });
    const rules = computed(() => {
      return {
        loginForm: {
            emailAddress: { required, email },
            password:  { required },
        }
      }
    })
    const v$ = useVuelidate(rules, state)
    return { state, v$ }
  },
 computed :{
    ...mapGetters(['companyUserStore'])
 },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isLoginFormSubmitted: false
    };
  },
  methods: {
    async handleCompanyUserLogin() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
       const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.login(this.state.loginForm.emailAddress, this.state.loginForm.password);
          this.notification = { text : "Success", level : "success" };  
            // set local storage token
            localStorage.setItem('jbpw-auth-token', result.data.Token);
            // store the user with vuex
            const companyUser = {
              EmailAddress : result.data.EmailAddress,
              IsAdmin : result.data.IsAdmin,
              HasSetCompanyDetails : result.data.HasSetCompanyDetails
            } 
            this.$store.dispatch('companyUserStore', companyUser);
            // set axios headers
            httpClient.defaults.headers.common['X-jbpw-auth'] = '' + result.data.Token
            // navigate to new page
            this.$router.push('/company-dashboard/');
            // notify the parent view  
            this.$emit('formsubmitted');
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
      }
    }
  },
};
</script>