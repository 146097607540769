<template>
  <div data-test-key="contact-us-page">
    <h1>Contact Us</h1>
     <ContactUsForm />
  </div>
</template>

<script>

import ContactUsForm from "@/components/Forms/ContactUsForm.vue";

export default {  
  name: "ContactUsView",
  components : {
    ContactUsForm
  }, 
  mounted() {
    document.title = 'Contact Us - TheJobTap.com'
  }
};
</script>