<template>
   <button style="cursor: pointer;" data-test-key="button-job-listing-apply" class="_jobListingApplyButton" v-on:click="handleApplicationSubmit">apply here</button>
</template>

<script>
import { jobListingApplicationAPI } from "@/api/jobListingApplication";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  props: ["applicationType", "applicationTypeValue", "jobListingTitle", "jobListingIdentifier"],
  components: {
  },
  methods: {

  },
  computed: {
    
  },
  mounted() {
    
  },
  methods: {

  async handleApplicationSubmit() 
    {
      if (this.applicationType == "Email")
      {
        console.log("mailto:" + this.applicationTypeValue);
        window.location.href = "mailto:" + this.applicationTypeValue + "?subject=" + this.jobListingTitle + "&body=message%20goes%20here";
      }
      if (this.applicationType == "WebsiteLink")
      {
        console.log("Linkto:" + this.applicationTypeValue);
        window.open(this.applicationTypeValue, '_blank').focus();
      }
      this.isApplicationSubmitted = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingApplicationAPI.submit(this.jobListingIdentifier, this.applicantSessionId);
        })
      );
      if (!result.success) {
        //this.notification = { text : result.data, level : "error" };        
        alert(result.data);
      }
    },
  }
}
</script>