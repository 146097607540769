import { JobListingApplication } from "@/domain/jobListingApplication/jobListingApplication";
import { httpClient } from "./http-client";
const basePath = "/job-listing/application";

export const jobListingApplicationAPI = {
    submit: async (jobListingIdentifier: string, applicantSessionId: string) => 
  {
    const url = basePath + "/submit";
    const response = await httpClient.post(url, {
        JobListingIdentifier: jobListingIdentifier,
        ApplicantSessionId : applicantSessionId
    });
    return response;
  },
  getByJobListingIdentifier: async (jobListingIdentifier: string) => 
  {
    // authenticated
    const url = basePath + "/getbyjoblistingidentifier/?joblistingidentifier=" + jobListingIdentifier;
    const response = await httpClient.get(url);
    return response.data as JobListingApplication [];
  },
}