import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/vuex";
import "./assets/styles/main.css";
import VueGtag from "vue-gtag-next";
import VueSocialSharing from 'vue-social-sharing'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(faUserSecret, fab)

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueGtag, { property: { id: "G-6RETER4P63" }});
app.use(VueSocialSharing);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount(`#app`);