<template>
    <h2>Company Visibility</h2>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <FeedbackNotification style="margin-bottom:30px;" v-if="this.notification.text != ''" 
         :feedbackText="this.notification.text" 
         :feedbackLevel="this.notification.level"/>
        <p>
          Show Page: 
          <input v-on:click="updateCompanyConfigurationHandler" v-model="state.updateCompanyConfigurationForm.showCompanyPage" type="checkbox" id="checkbox">
          <ValidationMessage v-if="v$.updateCompanyConfigurationForm.showCompanyPage.$error" :validationMessage="v$.updateCompanyConfigurationForm.showCompanyPage.$errors[0].$message" />
        </p>
    </div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "UpdateCompanyConfigurationForm",
  components : {
    FeedbackNotification, ValidationMessage
  }, 
  props: ["company"],
  emits: ["formsubmitted"],
  setup () {

    const state = reactive({
      updateCompanyConfigurationForm: {
        showCompanyPage : '', 
        }
      });

      const rules = computed(() => {
        return {
          updateCompanyConfigurationForm: {
            showCompanyPage : { required, },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  mounted(){
    this.setFormState();
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
    };
  },
  methods: {
    async updateCompanyConfigurationHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.notification = { text : "", level : "" };      
        this.pageLoading = true;

        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await companyAPI.updateCompanyConfiguration(!this.state.updateCompanyConfigurationForm.showCompanyPage);
            // success   
            if(result.data)
            {
              // notify the parent view  
              this.$emit('formsubmitted');
            } 
          })
        );
        if (!result.success) 
        {
          this.pageLoading = false;
          this.notification = { text : result.data, level : "error" };        
        }
      }
    },
    async setFormState() {
      // set the form state
      this.state.updateCompanyConfigurationForm.showCompanyPage = this.company.ShowCompanyPage;
      if (!this.state.updateCompanyConfigurationForm.showCompanyPage)
      {
        this.notification = { text : "Company is not visible on the Hiring Companies page", level : "warning" };      
      }
    },
  },
};
</script>