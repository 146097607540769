import { CompanyUser } from "@/domain/companyUser/companyUser";
import { httpClient } from "./http-client";
const basePath = "/company-user";

export const companyUserAPI = {
  login: async (emailAddress: string, password: string) => 
  {
    const url = basePath + "/login";
    return await httpClient.post(url, {
        emailaddress: emailAddress,
        password: password
      }, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
  },
  create: async (emailAddress: string) => 
  {
    const url = basePath + "/create";
    return await httpClient.post(url, {
        emailaddress: emailAddress,
      });
  },
  remove: async (identifier: string) => 
  {
    const url = basePath + "/remove";
    return await httpClient.post(url, {
      identifier: identifier,
      });
  },
  update: async (identifier: string, isActive: boolean, isAdmin: boolean) => 
  {
    const url = basePath + "/update";
    return await httpClient.post(url, {
      identifier: identifier,
      isActive: isActive,
      isAdmin: isAdmin,
    });
  },
  logout: async () => 
  {
    const url = basePath + "/logout";
    return await httpClient.post(url);
  },
  getByCompanyIdentifier: async () => 
  {
    const url = basePath + "/getbycompanyidentifier/";
    const response = await httpClient.get(url);
    return response.data as CompanyUser[];
  },
  processCompanyUserInvite: async (token: string, password: string) => 
  {
    const url = basePath + "/processcompanyuserinvite";
    return await httpClient.post(url, {
      token: token,
      password: password,
    });
  },
  processResetPassword: async (token: string, newPassword: string) => 
  {
    const url = basePath + "/processresetpassword";
    return await httpClient.post(url, {
      token: token,
      newPassword: newPassword,
    });
  },
  requestPasswordReset: async (emailAddress: string) => 
  {
    const url = basePath + "/requestpasswordreset";
    return await httpClient.post(url, {
      emailAddress: emailAddress
    });
  },
  get: async () => 
  {
    const url = basePath + "/get";
    const response = await httpClient.get(url);
    return response.data;
  },
  setAdminStatus: async (companyUserIdentifier: string) => 
  {
    const url = basePath + "/setadminstatus";
    return await httpClient.post(url, {
      companyUserIdentifier: companyUserIdentifier
    });
  },
  setActiveStatus: async (companyUserIdentifier: string) => 
  {
    const url = basePath + "/setactivestatus";
    return await httpClient.post(url, {
      companyUserIdentifier: companyUserIdentifier
    });
  }
}