import { InvoiceRecord } from "@/domain/invoice/invoiceRecord";
import { httpClient } from "./http-client";
const basePath = "/invoice-history";

export const invoiceHistoryAPI = {
  getInvoiceRecords: async () => 
  {
    const url = basePath + "/get";
    const response = await httpClient.get(url);
    return response.data as InvoiceRecord;
  },
};