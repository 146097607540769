<template>
<div data-test-key="company-manage-page">
  <h1>Manage Company</h1>

  <FeedbackNotification style="margin-bottom:30px;" v-if="this.notification.text != ''" 
         :feedbackText="this.notification.text" 
         :feedbackLevel="this.notification.level"/>
         
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-if="!formLoading && this.company != null">
    <UpdateCompanyConfigurationForm :company=this.company @formsubmitted="this.getCompany"/>
    <hr/>
    <CompanyImageForm :company=this.company @formsubmitted="this.getCompany"/>
    <hr/>
    <UpdateCompanyForm :company=this.company @formsubmitted="this.getCompany" />
    <hr/>
    <CompanyDeleteForm />
  </div>
</div>
</template>

<script>
import UpdateCompanyForm from "@/components/Forms/UpdateCompanyForm.vue";
import CompanyImageForm from "@/components/Forms/CompanyImageForm.vue";
import SetCompanyNotificationEmailAddressForm from "@/components/Forms/SetCompanyNotificationEmailAddressForm.vue";
import UpdateCompanyConfigurationForm from "@/components/Forms/UpdateCompanyConfigurationForm.vue";
import CompanyDeleteForm from "@/components/Forms/CompanyDeleteForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { mapGetters } from "vuex"; 

import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {  
  name: "CompanyManageView",
  components : {
    UpdateCompanyForm, CompanyImageForm, SetCompanyNotificationEmailAddressForm, UpdateCompanyConfigurationForm, CompanyDeleteForm, FeedbackNotification
  },  
  created() {
    if (this.companyUserStore == null)
    {
      this.$router.push('/');        
    }
    else
    {
      this.getCompany();
    }
  },
  computed :{
      ...mapGetters(['companyUserStore'])
  },
  mounted() {
    document.title = 'Manage Company - TheJobTap.com';
  },
  data() {
    return {
      company : null,
      notification : {
        text : "",
        level : "",
      },
      pageLoading : true,
      formLoading : true,
    }
  },
  methods: {
    
    async getCompany() {
      this.formLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.get();
          this.company = result;
        })
      );
      this.formLoading = false;
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };           
      }
    },
  },
};
</script>