<template>
  <h2>Delete Account</h2>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else-if="!isAccountDeletedFormSubmitted">
      <button class="btn" v-on:click="handleDeleteCompany">Delete Account</button>
  </div>
  <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
</template>

<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {  
  name: "CompanyDeleteForm",  
  components : {
    FeedbackNotification
  }, 
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isAccountDeletedFormSubmitted : false,
    };
  },
  methods: {
    async handleDeleteCompany() {
      this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          await companyAPI.requestCompanyRemove();
          this.notification = { 
            text : 'An email has been sent requesting your account to be deleted of all JobListings, company and all users. Click the link to delete the account.', 
            level : "notification" 
          };        
          this.isAccountDeletedFormSubmitted = true;
        })
      );
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };  
      }
    },
  },
};
</script>