import { Company } from "@/domain/company/company";
import { SearchCompaniesResult } from "@/domain/company/searchCompaniesResult";
import { httpClient } from "./http-client";
const basePath = "/company";

export const companyAPI = {
  getByName: async (companyName: string) => 
  {
    const url = basePath + "/getbyname?name=" + companyName;
    const response = await httpClient.get(url);
    return response.data as Company;
  },
  get: async () => 
  {
    // authenticated
    const url = basePath + "/get";
    const response = await httpClient.get(url);
    return response.data as Company;
  },
  search: async () => 
  {
    const url = basePath + "/search";
    const response = await httpClient.get(url);
    return response.data as SearchCompaniesResult;
  },
  register: async (name: string, emailAddress: string, password: string) => 
  {
    const url = basePath + "/register";
    return await httpClient.post(url, {
      name: name,
      emailaddress: emailAddress,
      password: password,
    });
  },
  setDetails: async (name: string, emailAddress: string, description: string) => 
  {
    const url = basePath + "/setdetails";
    return await httpClient.post(url, {
      name: name,
      emailAddress: emailAddress,
      description: description,
    });
  },
  update: async (name: string, website: string, description: string) => 
  {
    const url = basePath + "/update";
    return await httpClient.post(url, {
      website: website,
      name: name,
      description: description,
    });
  },
  uploadCompanyLogo: async (imageBytes: string) => 
  {
    const url = basePath + "/uploadcompanylogo";
    return await httpClient.post(url, {
      imageBytes: imageBytes,
    });
  },
  processEmailConfirmation: async (token: string) => 
  {
    const url = basePath + "/processemailconfirmation";
    return await httpClient.post(url, {
      token: token
    });
  },
  setNotificationEmail: async (emailAddress: string) => 
  {
    const url = basePath + "/setnotificationemail";
    return await httpClient.post(url, {
      emailAddress: emailAddress
    });
  },
  updateCompanyConfiguration: async (showCompanyPage: boolean) => 
  {
    const url = basePath + "/updatecompanyconfiguration";
    return await httpClient.post(url, {
      ShowCompanyPage: showCompanyPage
    });
  },
  requestCompanyRemove: async () => 
  {
    const url = basePath + "/requestcompanyremove";
    return await httpClient.post(url);
  },
  processDelete: async (token: string) => 
  {
    const url = basePath + "/processdelete";
    return await httpClient.post(url,{
        token: token
    });
  },
};