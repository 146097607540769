<template>
  <div data-test-key="token-page">
    <h1>Token {{ this.token }}</h1>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
    <div v-else>
      <div v-if="showProcessCompanyUserInviteForm">
        <CompanyProcessUserInviteForm @formsubmitted="formSubmittedEventHandler(e)" />
      </div>
      <div v-if="showProcessResetPasswordForm">
        <CompanyPasswordResetInputForm @formsubmitted="formSubmittedEventHandler(e)" />
      </div>
      <div v-if="showProcessCompanyDeleteForm">
        are you sure you want to delete?
        <button v-on:click="processCompanyDeleteHandler">Delete Company</button>
      </div>
    </div>
  </div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex"; 

const pageStatus = useStatus();
const log = useErrorLogger();

import CompanyPasswordResetInputForm from "@/components/Forms/CompanyPasswordResetInputForm.vue";
import CompanyProcessUserInviteForm from "@/components/Forms/CompanyProcessUserInviteForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

export default {  
  name: "CompanyLoginView",
  components : {
    CompanyPasswordResetInputForm, CompanyProcessUserInviteForm, FeedbackNotification
  }, 
  data() 
  {
    return {
      notification : {
        text : "",
        level : "",
      },
      token : this.$route.params.token,
      tokenType : this.$route.params.tokentype,
      showProcessCompanyUserInviteForm : false,
      showProcessResetPasswordForm : false,
      showProcessCompanyDeleteForm : false,
    };                  
  },
 created() {
    if (this.companyUserStore != null)
    {
      this.$router.push('/')        
    }
    else 
    {
      // check token is a guid
      switch(this.tokenType)
      {
        case "pcec":
            this.processCompanyEmailConfirmationHandler();
            return;
        case "pcui":
            this.showProcessCompanyUserInviteForm = true;
            return;
        case "pcurp":
            this.showProcessResetPasswordForm = true;
            return;
        case "pcd":
            this.showProcessCompanyDeleteForm = true;
            return;
        default:
            this.notification = { text : "Unable to define the correct token link", level : "notification" };        
            return;
      }    
    }
 },
  mounted() {
    document.title = 'Token Redemption - TheJobTap.com'
  },
 computed :{
    ...mapGetters(['companyUserStore'])
 },
  methods: {
  async formSubmittedEventHandler(value){

  },
  async processCompanyDeleteHandler(){
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.processDelete(this.token);
          console.log(result)
          if(result.data)
          {
            this.notification = { text : "Processed Company Delete", level : "success" };        
          }
        })
      );
      if (!result.success) {
          this.notification = { text : 'Unable to process Company Delete' + " [" + result.data + "]", level : "error" };        
      }
  },
  async processCompanyEmailConfirmationHandler(){
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.processEmailConfirmation(this.token);
          console.log(result)
          if(result.data)
          {
            this.notification = { text : "Processed Company Email Confirmation", level : "success" };        
          }
        })
      );
      if (!result.success) {
          this.notification = { text : 'Unable to process Company Email Confirmation' + " [" + result.data + "]", level : "error" };        
      }
   },
 }
};
</script>
