import { Article } from "@/domain/content/article";
import { ArticleCategory } from "@/domain/content/articleCategory";
import { SearchArticlesResult } from "@/domain/content/searchArticlesResult";

import { httpClient } from "./http-client";
const basePath = "/content";

export const contentAPI = {
  getArticle: async (slug: string) => 
  {
    const url = basePath + "/article?slug=" + slug;
    const response = await httpClient.get(url);
    return response.data as Article;
  },
  searchArticles: async (categoryName: string[]) => 
  {
    var categoryNameQuery = ''; 
    if(Array.isArray(categoryName))
    {
      categoryName.forEach(element => {
        if (element != '')
        {
          categoryNameQuery = categoryNameQuery + "&category=" + element;
        }
      });
    } 
    else
    {
      categoryNameQuery = "&category=" + categoryName;
    }

    const url = basePath + "/search/?" + categoryNameQuery;
    const response = await httpClient.get(url);
    return response.data as SearchArticlesResult;
  },
  getCategories: async () => 
  {
    const url = basePath + "/categories";
    const response = await httpClient.get(url);
    return response.data as ArticleCategory[];
  }, 
};