<template>
<div data-test-key="manage-members-page">
  <h1>Manage Members</h1>
  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div v-else>
    <CompanyInviteNewUserForm @formsubmitted="handleGetCompanyUsers"  />
    <h2>All Members</h2>
    <ul style="max-width: 500px; margin:auto; text-align: left; padding-bottom: 30px;">
      <li>Non-Admins can only:
        <ul>
          <li>View the Dashboard Page</li>
          <li>Create & Manage Job listings</li>
        </ul>
      </li>
    </ul>
    <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/> 
      <table style="margin-left: auto; margin-right: auto;">
        <tr>
          <td style="width:150px; font-weight: bold; text-align: left;">Account</td>
          <td style="width:130px; font-weight: bold;">Onboarded</td>
          <td style="width:100px; font-weight: bold;">Admin</td>
          <td style="width:100px; font-weight: bold;">Active</td>
          <td></td>
        </tr>
        <tr v-bind:key="companyUser.Identifier" v-for="companyUser in companyUsers">
          <td style="text-align: left;">{{ companyUser.EmailAddress }}</td>
          <td>
            {{ companyUser.IsAccountEmailConfirmed ? "Yes" : "No"}}
          </td>
          <td v-if="companyUser.EmailAddress != companyUserStore.EmailAddress && companyUser.IsActive && companyUser.IsAccountEmailConfirmed">
            <input type="checkbox" id="checkbox" v-model="companyUser.IsAdmin" v-on:click="setAdminStatusHandler(companyUser.Identifier)">
          </td>
          <td v-else>
            <input type="checkbox" disabled id="checkbox" v-model="companyUser.IsAdmin">
          </td>
          <td v-if="companyUser.EmailAddress != companyUserStore.EmailAddress && companyUser.IsAccountEmailConfirmed">
            <input type="checkbox" id="checkbox" v-model="companyUser.IsActive" v-on:click="setActiveStatusHandler(companyUser.Identifier)">
          </td>
          <td v-else>
            <input type="checkbox" disabled id="checkbox" v-model="companyUser.IsActive">
          </td>
          <td v-if="companyUser.EmailAddress != companyUserStore.EmailAddress">
            <button v-on:click="removeCompanyUserHandler(companyUser.Identifier)">remove</button>
            <button 
              v-if="companyUser.EmailAddress != companyUserStore.EmailAddress && !companyUser.IsAccountEmailConfirmed" 
              v-on:click="resendCompanyUserInviteHandler(companyUser.Identifier)">resend invite</button>
          </td>
        </tr>
      </table>
      
  </div>
</div>
</template>

<script>
import CompanyInviteNewUserForm from "@/components/Forms/CompanyInviteNewUserForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex"; 

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "CompanyManageUsersView",
  components : {
    CompanyInviteNewUserForm, FeedbackNotification
  },  
  data() {
    return {
      companyUsers : [],
      pageLoading : true,
      notification : {
        text : "",
        level : "",
      },
    };
  },
  created() {
    if (this.companyUserStore == null)
    {
      this.$router.push('/');        
    }
    else
    {
      this.handleGetCompanyUsers();
    }
 },
  mounted() {
    document.title = 'Manage Members - TheJobTap.com'
  },
 computed :{
    ...mapGetters(['companyUserStore'])
 },
  methods: {
    async handleGetCompanyUsers() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.getByCompanyIdentifier();
          this.companyUsers = result;
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };            
      }
    },
    async setAdminStatusHandler(identifier){
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.setAdminStatus(identifier);
          this.handleGetCompanyUsers();
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };             
      }
    },
  async setActiveStatusHandler(identifier){
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.setActiveStatus(identifier);
          this.handleGetCompanyUsers();
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };             
      }
    },
  async resendCompanyUserInviteHandler(identifier){
      alert("todo")
  },
    async removeCompanyUserHandler(identifier){
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.remove(identifier);
          this.handleGetCompanyUsers();
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };             
      }
    },
  }
};
</script>