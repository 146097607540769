<template>
  <div style="max-width: 800px; margin: auto;">
    <quill-editor
      ref="myQuillViewer"
      theme="bubble"
      :content="contentViewer"
      :options="editorOption"
      />
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import { quill } from 'quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

  export default {
  props: ["viewerText"],
  components: {
    quill, QuillEditor
  },
    data () {
      return {
        contentViewer: this.viewerText,
        editorOption: {
          toolbar: false,
          readOnly: true
        }
      }
    },
    computed: {
      editor() {
       return this.$refs.myQuillViewer
      }
    },
    mounted() {
      //this.content = this.viewerText;
      if(this.viewerText != ''){
        this.editor.setHTML(this.viewerText)
      }
    }
  }
</script>